export const sectionData = {
  section: "J",
  start: 0,
  end: 669,
  clip_end: 669,
  offset: "-5",
  location: "Onis",
  id: "J",
  range: [0, 669],
  height: 669,
  scaleFactor: 40.69357249626308,
};

export const sectionSymbols = [
  {
    id: 618,
    section_id: "J",
    symbol: "Domal stromatolites",
    start_height: "81",
    end_height: null,
    symbol_min_zoom: 0,
    height: "81.0000000000000000",
  },
  {
    id: 731,
    section_id: "J",
    symbol: "Digitate stromatolites",
    start_height: "339",
    end_height: "340.5",
    symbol_min_zoom: 0,
    height: "339.7500000000000000",
  },
  {
    id: 724,
    section_id: "J",
    symbol: "Digitate stromatolites",
    start_height: "327",
    end_height: "327.5",
    symbol_min_zoom: 1,
    height: "327.2500000000000000",
  },
  {
    id: 737,
    section_id: "J",
    symbol: "Trough cross-stratified",
    start_height: "365",
    end_height: null,
    symbol_min_zoom: 0,
    height: "365.0000000000000000",
  },
  {
    id: 580,
    section_id: "J",
    symbol: "Dessication cracks",
    start_height: "34.5",
    end_height: "36.5",
    symbol_min_zoom: 0,
    height: "35.5000000000000000",
  },
  {
    id: 692,
    section_id: "J",
    symbol: "Digitate stromatolites",
    start_height: "249.5",
    end_height: null,
    symbol_min_zoom: 0,
    height: "249.5000000000000000",
  },
  {
    id: 163,
    section_id: "J",
    symbol: "Hummocky cross-stratified",
    start_height: "574",
    end_height: null,
    symbol_min_zoom: 0,
    height: "574.0000000000000000",
  },
  {
    id: 664,
    section_id: "J",
    symbol: "Wavy",
    start_height: "180",
    end_height: null,
    symbol_min_zoom: 0,
    height: "180.0000000000000000",
  },
  {
    id: 801,
    section_id: "J",
    symbol: "Gutter cast",
    start_height: "611",
    end_height: null,
    symbol_min_zoom: 0,
    height: "611.0000000000000000",
  },
  {
    id: 150,
    section_id: "J",
    symbol: "Digitate stromatolites",
    start_height: "323",
    end_height: null,
    symbol_min_zoom: 1,
    height: "323.0000000000000000",
  },
  {
    id: 559,
    section_id: "J",
    symbol: "Cross-beds",
    start_height: "15.5",
    end_height: null,
    symbol_min_zoom: 0,
    height: "15.5000000000000000",
  },
  {
    id: 562,
    section_id: "J",
    symbol: "Cross-beds",
    start_height: "17.75",
    end_height: null,
    symbol_min_zoom: 0,
    height: "17.7500000000000000",
  },
  {
    id: 591,
    section_id: "J",
    symbol: "Cross-beds",
    start_height: "45",
    end_height: null,
    symbol_min_zoom: 0,
    height: "45.0000000000000000",
  },
  {
    id: 594,
    section_id: "J",
    symbol: "Domal stromatolites",
    start_height: "47.5",
    end_height: null,
    symbol_min_zoom: 0,
    height: "47.5000000000000000",
  },
  {
    id: 597,
    section_id: "J",
    symbol: "Domal stromatolites",
    start_height: "50",
    end_height: "57",
    symbol_min_zoom: 0,
    height: "53.5000000000000000",
  },
  {
    id: 571,
    section_id: "J",
    symbol: "Stromatolite?",
    start_height: "26.5",
    end_height: "27.75",
    symbol_min_zoom: 0,
    height: "27.1250000000000000",
  },
  {
    id: 660,
    section_id: "J",
    symbol: "Cross-beds",
    start_height: "175",
    end_height: "176",
    symbol_min_zoom: 0,
    height: "175.5000000000000000",
  },
  {
    id: 665,
    section_id: "J",
    symbol: "Wavy",
    start_height: "181",
    end_height: null,
    symbol_min_zoom: 0,
    height: "181.0000000000000000",
  },
  {
    id: 666,
    section_id: "J",
    symbol: "Wavy",
    start_height: "182",
    end_height: null,
    symbol_min_zoom: 0,
    height: "182.0000000000000000",
  },
  {
    id: 688,
    section_id: "J",
    symbol: "Digitate stromatolites",
    start_height: "243",
    end_height: "244",
    symbol_min_zoom: 0,
    height: "243.5000000000000000",
  },
  {
    id: 607,
    section_id: "J",
    symbol: "Domal stromatolites",
    start_height: "60",
    end_height: "61",
    symbol_min_zoom: 0,
    height: "60.5000000000000000",
  },
  {
    id: 674,
    section_id: "J",
    symbol: "Gutter cast",
    start_height: "204",
    end_height: "215",
    symbol_min_zoom: 0,
    height: "209.5000000000000000",
  },
  {
    id: 675,
    section_id: "J",
    symbol: "Gutter cast",
    start_height: "213",
    end_height: null,
    symbol_min_zoom: 0,
    height: "213.0000000000000000",
  },
  {
    id: 727,
    section_id: "J",
    symbol: "Ooids",
    start_height: "331.5",
    end_height: "332.5",
    symbol_min_zoom: 0,
    height: "332.0000000000000000",
  },
  {
    id: 745,
    section_id: "J",
    symbol: "Hummocky cross-stratified",
    start_height: "393",
    end_height: "395",
    symbol_min_zoom: 0,
    height: "394.0000000000000000",
  },
  {
    id: 721,
    section_id: "J",
    symbol: "Hummocky cross-stratified",
    start_height: "325",
    end_height: "326",
    symbol_min_zoom: 0,
    height: "325.5000000000000000",
  },
  {
    id: 755,
    section_id: "J",
    symbol: "Cross-beds",
    start_height: "432.5",
    end_height: null,
    symbol_min_zoom: 0,
    height: "432.5000000000000000",
  },
  {
    id: 763,
    section_id: "J",
    symbol: "Cross-beds",
    start_height: "468",
    end_height: null,
    symbol_min_zoom: 0,
    height: "468.0000000000000000",
  },
  {
    id: 762,
    section_id: "J",
    symbol: "Ooids",
    start_height: "462.5",
    end_height: "466.5",
    symbol_min_zoom: 0,
    height: "464.5000000000000000",
  },
  {
    id: 719,
    section_id: "J",
    symbol: "Digitate stromatolites",
    start_height: "323",
    end_height: "324",
    symbol_min_zoom: 1,
    height: "323.5000000000000000",
  },
  {
    id: 792,
    section_id: "J",
    symbol: "Ooids",
    start_height: "583.5",
    end_height: "584.5",
    symbol_min_zoom: 0,
    height: "584.0000000000000000",
  },
  {
    id: 613,
    section_id: "J",
    symbol: "Domal stromatolites",
    start_height: "71.5",
    end_height: "73",
    symbol_min_zoom: 0,
    height: "72.2500000000000000",
  },
  {
    id: 700,
    section_id: "J",
    symbol: "Cross-beds",
    start_height: "290",
    end_height: "291",
    symbol_min_zoom: 0,
    height: "290.5000000000000000",
  },
  {
    id: 817,
    section_id: "J",
    symbol: "Trough cross-stratified",
    start_height: "649",
    end_height: "669",
    symbol_min_zoom: 0,
    height: "659.0000000000000000",
  },
  {
    id: 165,
    section_id: "J",
    symbol: "Ooids",
    start_height: "592",
    end_height: "595",
    symbol_min_zoom: 0,
    height: "593.5000000000000000",
  },
  {
    id: 765,
    section_id: "J",
    symbol: "Hummocky cross-stratified",
    start_height: "472",
    end_height: "480",
    symbol_min_zoom: 0,
    height: "476.0000000000000000",
  },
];

export const sectionSurfaces = [
  {
    id: 640,
    facies: null,
    facies_tract: null,
    section_id: "J",
    facies_color: null,
    lithology: null,
    covered: false,
    flooding_surface_order: null,
    surface: null,
    note: null,
    definite_boundary: true,
    pattern: null,
    schematic: false,
    fgdc_pattern: null,
    bottom: -20,
    top: 0,
    tree: null,
    grainsize: null,
    surface_type: "mfs",
    surface_order: 0,
    surface_certainty: null,
    fill_pattern: null,
  },
  {
    id: 359,
    facies: "none",
    facies_tract: null,
    section_id: "J",
    facies_color: "#969696",
    lithology: "limestone",
    covered: false,
    flooding_surface_order: null,
    surface: null,
    note: null,
    definite_boundary: true,
    pattern: "limestone",
    schematic: false,
    fgdc_pattern: null,
    bottom: 0,
    top: 8.5,
    tree: ["carbonate", "limestone"],
    grainsize: "f",
    surface_type: null,
    surface_order: 0,
    surface_certainty: null,
    fill_pattern: "limestone",
  },
  {
    id: 360,
    facies: null,
    facies_tract: null,
    section_id: "J",
    facies_color: null,
    lithology: "sandy dolomite",
    covered: false,
    flooding_surface_order: null,
    surface: null,
    note: null,
    definite_boundary: true,
    pattern: "sandy-dolomite",
    schematic: false,
    fgdc_pattern: null,
    bottom: 8.5,
    top: 13.5,
    tree: ["carbonate", "dolomite", "sandy dolomite"],
    grainsize: "vc",
    surface_type: null,
    surface_order: null,
    surface_certainty: null,
    fill_pattern: "sandy-dolomite",
  },
  {
    id: 361,
    facies: null,
    facies_tract: null,
    section_id: "J",
    facies_color: null,
    lithology: "sandy dolomite",
    covered: false,
    flooding_surface_order: null,
    surface: null,
    note: null,
    definite_boundary: true,
    pattern: "sandy-dolomite",
    schematic: false,
    fgdc_pattern: null,
    bottom: 13.5,
    top: 14.3,
    tree: ["carbonate", "dolomite", "sandy dolomite"],
    grainsize: "f",
    surface_type: null,
    surface_order: null,
    surface_certainty: null,
    fill_pattern: "sandy-dolomite",
  },
  {
    id: 362,
    facies: "carbonate-mudstone",
    facies_tract: "peritidal",
    section_id: "J",
    facies_color: "#bbdefb",
    lithology: "dolomite",
    covered: false,
    flooding_surface_order: null,
    surface: 25,
    note: "Basal thrust of NNC",
    definite_boundary: true,
    pattern: "dolomite",
    schematic: false,
    fgdc_pattern: null,
    bottom: 14.3,
    top: 21.8,
    tree: ["carbonate", "dolomite"],
    grainsize: "ms",
    surface_type: "sb",
    surface_order: 1,
    surface_certainty: null,
    fill_pattern: "dolomite",
  },
  {
    id: 363,
    facies: null,
    facies_tract: null,
    section_id: "J",
    facies_color: null,
    lithology: "sandstone",
    covered: false,
    flooding_surface_order: null,
    surface: null,
    note: null,
    definite_boundary: true,
    pattern: "sandstone",
    schematic: false,
    fgdc_pattern: null,
    bottom: 21.8,
    top: 22,
    tree: ["clastic", "sandstone"],
    grainsize: "vf",
    surface_type: null,
    surface_order: null,
    surface_certainty: null,
    fill_pattern: "sandstone",
  },
  {
    id: 364,
    facies: null,
    facies_tract: null,
    section_id: "J",
    facies_color: null,
    lithology: "dolomite",
    covered: false,
    flooding_surface_order: null,
    surface: null,
    note: null,
    definite_boundary: true,
    pattern: "dolomite",
    schematic: false,
    fgdc_pattern: null,
    bottom: 22,
    top: 24.5,
    tree: ["carbonate", "dolomite"],
    grainsize: "ms",
    surface_type: null,
    surface_order: null,
    surface_certainty: null,
    fill_pattern: "dolomite",
  },
  {
    id: 365,
    facies: "fine-clastics",
    facies_tract: null,
    section_id: "J",
    facies_color: "#dcedc8",
    lithology: "shale",
    covered: false,
    flooding_surface_order: 3,
    surface: null,
    note: null,
    definite_boundary: true,
    pattern: "shale",
    schematic: false,
    fgdc_pattern: null,
    bottom: 24.5,
    top: 26.8,
    tree: ["clastic", "shale"],
    grainsize: "s",
    surface_type: "mfs",
    surface_order: 1,
    surface_certainty: null,
    fill_pattern: "shale",
  },
  {
    id: 366,
    facies: "carbonate-mudstone",
    facies_tract: null,
    section_id: "J",
    facies_color: "#bbdefb",
    lithology: "dolomite",
    covered: false,
    flooding_surface_order: null,
    surface: null,
    note: null,
    definite_boundary: true,
    pattern: "dolomite",
    schematic: false,
    fgdc_pattern: null,
    bottom: 26.8,
    top: 27.8,
    tree: ["carbonate", "dolomite"],
    grainsize: "ms",
    surface_type: null,
    surface_order: null,
    surface_certainty: null,
    fill_pattern: "dolomite",
  },
  {
    id: 367,
    facies: "fine-clastics",
    facies_tract: null,
    section_id: "J",
    facies_color: "#dcedc8",
    lithology: "siltstone",
    covered: false,
    flooding_surface_order: 3,
    surface: null,
    note: null,
    definite_boundary: true,
    pattern: "siltstone",
    schematic: false,
    fgdc_pattern: null,
    bottom: 27.8,
    top: 30.3,
    tree: ["clastic", "siltstone"],
    grainsize: "s",
    surface_type: "mfs",
    surface_order: 3,
    surface_certainty: null,
    fill_pattern: "siltstone",
  },
  {
    id: 368,
    facies: "carbonate-mudstone",
    facies_tract: null,
    section_id: "J",
    facies_color: "#bbdefb",
    lithology: "dolomite",
    covered: false,
    flooding_surface_order: null,
    surface: null,
    note: null,
    definite_boundary: true,
    pattern: "dolomite",
    schematic: false,
    fgdc_pattern: null,
    bottom: 30.3,
    top: 31,
    tree: ["carbonate", "dolomite"],
    grainsize: "s",
    surface_type: null,
    surface_order: null,
    surface_certainty: null,
    fill_pattern: "dolomite",
  },
  {
    id: 369,
    facies: null,
    facies_tract: null,
    section_id: "J",
    facies_color: null,
    lithology: "sandy dolomite",
    covered: false,
    flooding_surface_order: null,
    surface: null,
    note: null,
    definite_boundary: true,
    pattern: "sandy-dolomite",
    schematic: false,
    fgdc_pattern: null,
    bottom: 31,
    top: 31.3,
    tree: ["carbonate", "dolomite", "sandy dolomite"],
    grainsize: "f",
    surface_type: null,
    surface_order: null,
    surface_certainty: null,
    fill_pattern: "sandy-dolomite",
  },
  {
    id: 370,
    facies: "carbonate-mudstone",
    facies_tract: null,
    section_id: "J",
    facies_color: "#bbdefb",
    lithology: "dolomite",
    covered: false,
    flooding_surface_order: null,
    surface: null,
    note: null,
    definite_boundary: true,
    pattern: "dolomite",
    schematic: false,
    fgdc_pattern: null,
    bottom: 31.3,
    top: 34.5,
    tree: ["carbonate", "dolomite"],
    grainsize: "ms",
    surface_type: null,
    surface_order: null,
    surface_certainty: null,
    fill_pattern: "dolomite",
  },
  {
    id: 371,
    facies: null,
    facies_tract: null,
    section_id: "J",
    facies_color: null,
    lithology: "dolomite",
    covered: false,
    flooding_surface_order: null,
    surface: null,
    note: null,
    definite_boundary: true,
    pattern: "dolomite",
    schematic: false,
    fgdc_pattern: null,
    bottom: 34.5,
    top: 36.5,
    tree: ["carbonate", "dolomite"],
    grainsize: "ms",
    surface_type: null,
    surface_order: null,
    surface_certainty: null,
    fill_pattern: "dolomite",
  },
  {
    id: 372,
    facies: null,
    facies_tract: null,
    section_id: "J",
    facies_color: null,
    lithology: "dolomite",
    covered: false,
    flooding_surface_order: null,
    surface: null,
    note: null,
    definite_boundary: true,
    pattern: "dolomite",
    schematic: false,
    fgdc_pattern: null,
    bottom: 36.5,
    top: 46.08,
    tree: ["carbonate", "dolomite"],
    grainsize: "ms",
    surface_type: "sb",
    surface_order: 0,
    surface_certainty: null,
    fill_pattern: "dolomite",
  },
  {
    id: 620,
    facies: null,
    facies_tract: null,
    section_id: "J",
    facies_color: null,
    lithology: null,
    covered: false,
    flooding_surface_order: 4,
    surface: null,
    note: null,
    definite_boundary: true,
    pattern: null,
    schematic: false,
    fgdc_pattern: null,
    bottom: 46.08,
    top: 50,
    tree: null,
    grainsize: null,
    surface_type: "sb",
    surface_order: 3,
    surface_certainty: null,
    fill_pattern: null,
  },
  {
    id: 373,
    facies: "coarse-clastics",
    facies_tract: null,
    section_id: "J",
    facies_color: "#006064",
    lithology: "sandstone",
    covered: false,
    flooding_surface_order: null,
    surface: null,
    note: null,
    definite_boundary: true,
    pattern: "sandstone",
    schematic: false,
    fgdc_pattern: null,
    bottom: 50,
    top: 50.2,
    tree: ["clastic", "sandstone"],
    grainsize: "c",
    surface_type: "sb",
    surface_order: null,
    surface_certainty: null,
    fill_pattern: "sandstone",
  },
  {
    id: 374,
    facies: "carbonate-mudstone",
    facies_tract: null,
    section_id: "J",
    facies_color: "#bbdefb",
    lithology: "dolomite",
    covered: false,
    flooding_surface_order: 5,
    surface: null,
    note: null,
    definite_boundary: true,
    pattern: "dolomite",
    schematic: false,
    fgdc_pattern: null,
    bottom: 50.2,
    top: 57.5,
    tree: ["carbonate", "dolomite"],
    grainsize: "ms",
    surface_type: "mfs",
    surface_order: 2,
    surface_certainty: null,
    fill_pattern: "dolomite",
  },
  {
    id: 722,
    facies: null,
    facies_tract: null,
    section_id: "J",
    facies_color: null,
    lithology: null,
    covered: false,
    flooding_surface_order: null,
    surface: 33,
    note: "Neuras internal sequence boundary 1",
    definite_boundary: true,
    pattern: null,
    schematic: false,
    fgdc_pattern: null,
    bottom: 57.5,
    top: 67.3,
    tree: null,
    grainsize: null,
    surface_type: null,
    surface_order: null,
    surface_certainty: null,
    fill_pattern: null,
  },
  {
    id: 375,
    facies: null,
    facies_tract: null,
    section_id: "J",
    facies_color: null,
    lithology: "sandy dolomite",
    covered: false,
    flooding_surface_order: null,
    surface: null,
    note: null,
    definite_boundary: true,
    pattern: "sandy-dolomite",
    schematic: false,
    fgdc_pattern: null,
    bottom: 67.3,
    top: 67.5,
    tree: ["carbonate", "dolomite", "sandy dolomite"],
    grainsize: "f",
    surface_type: null,
    surface_order: null,
    surface_certainty: null,
    fill_pattern: "sandy-dolomite",
  },
  {
    id: 376,
    facies: null,
    facies_tract: null,
    section_id: "J",
    facies_color: null,
    lithology: "dolomite",
    covered: false,
    flooding_surface_order: null,
    surface: null,
    note: null,
    definite_boundary: true,
    pattern: "dolomite",
    schematic: false,
    fgdc_pattern: null,
    bottom: 67.5,
    top: 85,
    tree: ["carbonate", "dolomite"],
    grainsize: "ms",
    surface_type: "mfs",
    surface_order: 2,
    surface_certainty: null,
    fill_pattern: "dolomite",
  },
  {
    id: 377,
    facies: "coarse-clastics",
    facies_tract: "shoreface-alluvial-siliciclastic",
    section_id: "J",
    facies_color: "#006064",
    lithology: "sandstone",
    covered: false,
    flooding_surface_order: -1,
    surface: 18,
    note: "Coarse sandstone in Ubisis",
    definite_boundary: true,
    pattern: "sandstone",
    schematic: false,
    fgdc_pattern: null,
    bottom: 85,
    top: 90.4,
    tree: ["clastic", "sandstone"],
    grainsize: "vc",
    surface_type: "mfs",
    surface_order: 2,
    surface_certainty: null,
    fill_pattern: "sandstone",
  },
  {
    id: 378,
    facies: "carbonate-mudstone",
    facies_tract: null,
    section_id: "J",
    facies_color: "#bbdefb",
    lithology: "dolomite",
    covered: false,
    flooding_surface_order: 4,
    surface: null,
    note: null,
    definite_boundary: true,
    pattern: "dolomite",
    schematic: false,
    fgdc_pattern: null,
    bottom: 90.4,
    top: 91.5,
    tree: ["carbonate", "dolomite"],
    grainsize: "ms",
    surface_type: null,
    surface_order: 3,
    surface_certainty: null,
    fill_pattern: "dolomite",
  },
  {
    id: 379,
    facies: "fine-clastics",
    facies_tract: null,
    section_id: "J",
    facies_color: "#dcedc8",
    lithology: "shale",
    covered: true,
    flooding_surface_order: 4,
    surface: null,
    note: null,
    definite_boundary: true,
    pattern: "shale",
    schematic: false,
    fgdc_pattern: null,
    bottom: 91.5,
    top: 92,
    tree: ["clastic", "shale"],
    grainsize: "ms",
    surface_type: "mfs",
    surface_order: 3,
    surface_certainty: null,
    fill_pattern: "shale",
  },
  {
    id: 380,
    facies: "coarse-clastics",
    facies_tract: null,
    section_id: "J",
    facies_color: "#006064",
    lithology: "sandstone",
    covered: true,
    flooding_surface_order: -3,
    surface: null,
    note: null,
    definite_boundary: true,
    pattern: "sandstone",
    schematic: false,
    fgdc_pattern: null,
    bottom: 92,
    top: 95,
    tree: ["clastic", "sandstone"],
    grainsize: "vc",
    surface_type: "sb",
    surface_order: 3,
    surface_certainty: null,
    fill_pattern: "sandstone",
  },
  {
    id: 381,
    facies: "fine-clastics",
    facies_tract: "marine-siliciclastic",
    section_id: "J",
    facies_color: "#dcedc8",
    lithology: "siltstone",
    covered: true,
    flooding_surface_order: 3,
    surface: 4,
    note: "CC1",
    definite_boundary: true,
    pattern: "siltstone",
    schematic: false,
    fgdc_pattern: null,
    bottom: 95,
    top: 112,
    tree: ["clastic", "siltstone"],
    grainsize: "s",
    surface_type: "mfs",
    surface_order: 0,
    surface_certainty: null,
    fill_pattern: "624",
  },
  {
    id: 382,
    facies: "coarse-clastics",
    facies_tract: "shoreface-alluvial-siliciclastic",
    section_id: "J",
    facies_color: "#006064",
    lithology: "siltstone",
    covered: false,
    flooding_surface_order: null,
    surface: null,
    note: null,
    definite_boundary: true,
    pattern: "siltstone",
    schematic: false,
    fgdc_pattern: null,
    bottom: 112,
    top: 113.7,
    tree: ["clastic", "siltstone"],
    grainsize: "c",
    surface_type: "sb",
    surface_order: 2,
    surface_certainty: null,
    fill_pattern: "siltstone",
  },
  {
    id: 383,
    facies: "fine-clastics",
    facies_tract: "marine-siliciclastic",
    section_id: "J",
    facies_color: "#dcedc8",
    lithology: "siltstone",
    covered: true,
    flooding_surface_order: 4,
    surface: null,
    note: null,
    definite_boundary: true,
    pattern: "siltstone",
    schematic: false,
    fgdc_pattern: null,
    bottom: 113.7,
    top: 119,
    tree: ["clastic", "siltstone"],
    grainsize: "s",
    surface_type: "mfs",
    surface_order: 2,
    surface_certainty: 4,
    fill_pattern: "624",
  },
  {
    id: 384,
    facies: null,
    facies_tract: null,
    section_id: "J",
    facies_color: null,
    lithology: "siltstone",
    covered: false,
    flooding_surface_order: null,
    surface: null,
    note: null,
    definite_boundary: true,
    pattern: "siltstone",
    schematic: false,
    fgdc_pattern: null,
    bottom: 119,
    top: 119.6,
    tree: ["clastic", "siltstone"],
    grainsize: "s",
    surface_type: null,
    surface_order: null,
    surface_certainty: null,
    fill_pattern: "siltstone",
  },
  {
    id: 385,
    facies: null,
    facies_tract: null,
    section_id: "J",
    facies_color: null,
    lithology: "siltstone",
    covered: true,
    flooding_surface_order: null,
    surface: null,
    note: null,
    definite_boundary: true,
    pattern: "siltstone",
    schematic: false,
    fgdc_pattern: null,
    bottom: 119.6,
    top: 120.6,
    tree: ["clastic", "siltstone"],
    grainsize: "s",
    surface_type: null,
    surface_order: null,
    surface_certainty: null,
    fill_pattern: "624",
  },
  {
    id: 386,
    facies: "coarse-clastics",
    facies_tract: "shoreface-alluvial-siliciclastic",
    section_id: "J",
    facies_color: "#006064",
    lithology: "sandstone",
    covered: false,
    flooding_surface_order: null,
    surface: null,
    note: null,
    definite_boundary: true,
    pattern: "sandstone",
    schematic: false,
    fgdc_pattern: null,
    bottom: 120.6,
    top: 122,
    tree: ["clastic", "sandstone"],
    grainsize: "c",
    surface_type: "sb",
    surface_order: 2,
    surface_certainty: null,
    fill_pattern: "sandstone",
  },
  {
    id: 387,
    facies: "fine-clastics",
    facies_tract: "marine-siliciclastic",
    section_id: "J",
    facies_color: "#dcedc8",
    lithology: "siltstone",
    covered: true,
    flooding_surface_order: 3,
    surface: null,
    note: null,
    definite_boundary: true,
    pattern: "siltstone",
    schematic: false,
    fgdc_pattern: null,
    bottom: 122,
    top: 124,
    tree: ["clastic", "siltstone"],
    grainsize: "s",
    surface_type: "mfs",
    surface_order: 3,
    surface_certainty: null,
    fill_pattern: "624",
  },
  {
    id: 388,
    facies: "coarse-clastics",
    facies_tract: "shoreface-alluvial-siliciclastic",
    section_id: "J",
    facies_color: "#006064",
    lithology: "sandstone",
    covered: false,
    flooding_surface_order: -4,
    surface: null,
    note: null,
    definite_boundary: true,
    pattern: "sandstone",
    schematic: false,
    fgdc_pattern: null,
    bottom: 124,
    top: 124.5,
    tree: ["clastic", "sandstone"],
    grainsize: "c",
    surface_type: "sb",
    surface_order: 3,
    surface_certainty: null,
    fill_pattern: "sandstone",
  },
  {
    id: 389,
    facies: "fine-clastics",
    facies_tract: "shoreface-alluvial-siliciclastic",
    section_id: "J",
    facies_color: "#dcedc8",
    lithology: "shale",
    covered: false,
    flooding_surface_order: 5,
    surface: null,
    note: null,
    definite_boundary: true,
    pattern: "shale",
    schematic: false,
    fgdc_pattern: null,
    bottom: 124.5,
    top: 125,
    tree: ["clastic", "shale"],
    grainsize: "s",
    surface_type: "mfs",
    surface_order: 3,
    surface_certainty: null,
    fill_pattern: "shale",
  },
  {
    id: 390,
    facies: "coarse-clastics",
    facies_tract: "shoreface-alluvial-siliciclastic",
    section_id: "J",
    facies_color: "#006064",
    lithology: "sandstone",
    covered: false,
    flooding_surface_order: null,
    surface: 21,
    note: "Ubisis Fm 1",
    definite_boundary: true,
    pattern: "sandstone",
    schematic: false,
    fgdc_pattern: null,
    bottom: 125,
    top: 128,
    tree: ["clastic", "sandstone"],
    grainsize: "c",
    surface_type: "sb",
    surface_order: 1,
    surface_certainty: 8,
    fill_pattern: "sandstone",
  },
  {
    id: 391,
    facies: "fine-clastics",
    facies_tract: null,
    section_id: "J",
    facies_color: "#dcedc8",
    lithology: "shale",
    covered: false,
    flooding_surface_order: 5,
    surface: null,
    note: null,
    definite_boundary: true,
    pattern: "shale",
    schematic: false,
    fgdc_pattern: null,
    bottom: 128,
    top: 128.5,
    tree: ["clastic", "shale"],
    grainsize: "s",
    surface_type: "mfs",
    surface_order: 3,
    surface_certainty: null,
    fill_pattern: "shale",
  },
  {
    id: 392,
    facies: "coarse-clastics",
    facies_tract: "shoreface-alluvial-siliciclastic",
    section_id: "J",
    facies_color: "#006064",
    lithology: "sandstone",
    covered: false,
    flooding_surface_order: null,
    surface: null,
    note: null,
    definite_boundary: true,
    pattern: "sandstone",
    schematic: false,
    fgdc_pattern: null,
    bottom: 128.5,
    top: 133,
    tree: ["clastic", "sandstone"],
    grainsize: "c",
    surface_type: null,
    surface_order: null,
    surface_certainty: null,
    fill_pattern: "609",
  },
  {
    id: 393,
    facies: "shallow-fine-clastics",
    facies_tract: null,
    section_id: "J",
    facies_color: "#4db6ac",
    lithology: "sandstone",
    covered: false,
    flooding_surface_order: null,
    surface: null,
    note: null,
    definite_boundary: true,
    pattern: "sandstone",
    schematic: false,
    fgdc_pattern: null,
    bottom: 133,
    top: 135,
    tree: ["clastic", "sandstone"],
    grainsize: "vf",
    surface_type: "mfs",
    surface_order: 3,
    surface_certainty: null,
    fill_pattern: "sandstone",
  },
  {
    id: 394,
    facies: "coarse-clastics",
    facies_tract: null,
    section_id: "J",
    facies_color: "#006064",
    lithology: "sandstone",
    covered: false,
    flooding_surface_order: null,
    surface: null,
    note: null,
    definite_boundary: true,
    pattern: "sandstone",
    schematic: false,
    fgdc_pattern: null,
    bottom: 135,
    top: 136,
    tree: ["clastic", "sandstone"],
    grainsize: "c",
    surface_type: null,
    surface_order: null,
    surface_certainty: null,
    fill_pattern: "sandstone",
  },
  {
    id: 395,
    facies: "fine-clastics",
    facies_tract: null,
    section_id: "J",
    facies_color: "#dcedc8",
    lithology: "siltstone",
    covered: false,
    flooding_surface_order: 4,
    surface: null,
    note: null,
    definite_boundary: true,
    pattern: "siltstone",
    schematic: false,
    fgdc_pattern: null,
    bottom: 136,
    top: 137,
    tree: ["clastic", "siltstone"],
    grainsize: "ms",
    surface_type: "mfs",
    surface_order: 2,
    surface_certainty: null,
    fill_pattern: "siltstone",
  },
  {
    id: 396,
    facies: "shallow-fine-clastics",
    facies_tract: "peritidal-siliciclastic",
    section_id: "J",
    facies_color: "#4db6ac",
    lithology: "sandstone",
    covered: true,
    flooding_surface_order: null,
    surface: null,
    note: null,
    definite_boundary: true,
    pattern: "sandstone",
    schematic: false,
    fgdc_pattern: null,
    bottom: 137,
    top: 142,
    tree: ["clastic", "sandstone"],
    grainsize: "s",
    surface_type: null,
    surface_order: null,
    surface_certainty: null,
    fill_pattern: "sandstone",
  },
  {
    id: 397,
    facies: "coarse-clastics",
    facies_tract: "shoreface-alluvial-siliciclastic",
    section_id: "J",
    facies_color: "#006064",
    lithology: "sandstone",
    covered: false,
    flooding_surface_order: null,
    surface: null,
    note: null,
    definite_boundary: true,
    pattern: "sandstone",
    schematic: false,
    fgdc_pattern: null,
    bottom: 142,
    top: 143.5,
    tree: ["clastic", "sandstone"],
    grainsize: "c",
    surface_type: null,
    surface_order: null,
    surface_certainty: null,
    fill_pattern: "sandstone",
  },
  {
    id: 398,
    facies: "coarse-clastics",
    facies_tract: "shoreface-alluvial-siliciclastic",
    section_id: "J",
    facies_color: "#006064",
    lithology: "sandstone",
    covered: false,
    flooding_surface_order: null,
    surface: null,
    note: null,
    definite_boundary: true,
    pattern: "sandstone",
    schematic: false,
    fgdc_pattern: null,
    bottom: 143.5,
    top: 147,
    tree: ["clastic", "sandstone"],
    grainsize: "c",
    surface_type: null,
    surface_order: null,
    surface_certainty: null,
    fill_pattern: "sandstone",
  },
  {
    id: 399,
    facies: null,
    facies_tract: null,
    section_id: "J",
    facies_color: null,
    lithology: "conglomerate",
    covered: false,
    flooding_surface_order: null,
    surface: null,
    note: null,
    definite_boundary: true,
    pattern: "conglomerate",
    schematic: false,
    fgdc_pattern: null,
    bottom: 147,
    top: 147.5,
    tree: ["clastic", "conglomerate"],
    grainsize: "p",
    surface_type: "sb",
    surface_order: 4,
    surface_certainty: null,
    fill_pattern: "601",
  },
  {
    id: 400,
    facies: "fine-clastics",
    facies_tract: "peritidal-siliciclastic",
    section_id: "J",
    facies_color: "#dcedc8",
    lithology: "siltstone",
    covered: false,
    flooding_surface_order: 3,
    surface: null,
    note: null,
    definite_boundary: true,
    pattern: "siltstone",
    schematic: false,
    fgdc_pattern: null,
    bottom: 147.5,
    top: 149.2,
    tree: ["clastic", "siltstone"],
    grainsize: "s",
    surface_type: "mfs",
    surface_order: 2,
    surface_certainty: null,
    fill_pattern: "siltstone",
  },
  {
    id: 401,
    facies: "coarse-clastics",
    facies_tract: "shoreface-alluvial-siliciclastic",
    section_id: "J",
    facies_color: "#006064",
    lithology: "sandstone",
    covered: false,
    flooding_surface_order: null,
    surface: null,
    note: null,
    definite_boundary: true,
    pattern: "sandstone",
    schematic: false,
    fgdc_pattern: null,
    bottom: 149.2,
    top: 157,
    tree: ["clastic", "sandstone"],
    grainsize: "c",
    surface_type: null,
    surface_order: 2,
    surface_certainty: null,
    fill_pattern: "609",
  },
  {
    id: 402,
    facies: "carbonate-mudstone",
    facies_tract: "restricted-subtidal",
    section_id: "J",
    facies_color: "#bbdefb",
    lithology: "dolomite",
    covered: false,
    flooding_surface_order: 2,
    surface: 2,
    note: "Top of Chain Clastics",
    definite_boundary: true,
    pattern: "dolomite",
    schematic: false,
    fgdc_pattern: null,
    bottom: 157,
    top: 163.92,
    tree: ["carbonate", "dolomite"],
    grainsize: "ms",
    surface_type: "mfs",
    surface_order: 1,
    surface_certainty: null,
    fill_pattern: "dolomite",
  },
  {
    id: 641,
    facies: null,
    facies_tract: null,
    section_id: "J",
    facies_color: null,
    lithology: null,
    covered: false,
    flooding_surface_order: null,
    surface: 23,
    note: "Tsams A Middle",
    definite_boundary: true,
    pattern: null,
    schematic: false,
    fgdc_pattern: null,
    bottom: 163.92,
    top: 172.35,
    tree: null,
    grainsize: null,
    surface_type: null,
    surface_order: null,
    surface_certainty: null,
    fill_pattern: null,
  },
  {
    id: 678,
    facies: null,
    facies_tract: null,
    section_id: "J",
    facies_color: null,
    lithology: null,
    covered: false,
    flooding_surface_order: null,
    surface: null,
    note: null,
    definite_boundary: true,
    pattern: null,
    schematic: false,
    fgdc_pattern: null,
    bottom: 172.35,
    top: 173.85,
    tree: null,
    grainsize: "vf",
    surface_type: "sb",
    surface_order: 2,
    surface_certainty: null,
    fill_pattern: null,
  },
  {
    id: 677,
    facies: null,
    facies_tract: null,
    section_id: "J",
    facies_color: null,
    lithology: null,
    covered: false,
    flooding_surface_order: null,
    surface: null,
    note: null,
    definite_boundary: false,
    pattern: null,
    schematic: false,
    fgdc_pattern: null,
    bottom: 173.85,
    top: 175,
    tree: null,
    grainsize: "f",
    surface_type: null,
    surface_order: null,
    surface_certainty: null,
    fill_pattern: null,
  },
  {
    id: 403,
    facies: "fine-clastics",
    facies_tract: null,
    section_id: "J",
    facies_color: "#dcedc8",
    lithology: "dolomitic siltstone",
    covered: false,
    flooding_surface_order: null,
    surface: 9,
    note: "Top of Tsams A",
    definite_boundary: true,
    pattern: "dolomitic siltstone",
    schematic: false,
    fgdc_pattern: null,
    bottom: 175,
    top: 176,
    tree: ["clastic", "siltstone", "dolomitic siltstone"],
    grainsize: "s",
    surface_type: "mfs",
    surface_order: 2,
    surface_certainty: null,
    fill_pattern: "dolomitic siltstone",
  },
  {
    id: 404,
    facies: null,
    facies_tract: null,
    section_id: "J",
    facies_color: null,
    lithology: "dolomitic siltstone",
    covered: false,
    flooding_surface_order: null,
    surface: null,
    note: null,
    definite_boundary: true,
    pattern: "dolomitic siltstone",
    schematic: false,
    fgdc_pattern: null,
    bottom: 176,
    top: 176.4,
    tree: ["clastic", "siltstone", "dolomitic siltstone"],
    grainsize: "s",
    surface_type: null,
    surface_order: null,
    surface_certainty: null,
    fill_pattern: "dolomitic siltstone",
  },
  {
    id: 405,
    facies: "fine-clastics",
    facies_tract: null,
    section_id: "J",
    facies_color: "#dcedc8",
    lithology: "dolomite",
    covered: false,
    flooding_surface_order: null,
    surface: null,
    note: null,
    definite_boundary: true,
    pattern: "dolomite",
    schematic: false,
    fgdc_pattern: null,
    bottom: 176.4,
    top: 176.6,
    tree: ["carbonate", "dolomite"],
    grainsize: "s",
    surface_type: null,
    surface_order: null,
    surface_certainty: null,
    fill_pattern: "dolomite",
  },
  {
    id: 406,
    facies: null,
    facies_tract: null,
    section_id: "J",
    facies_color: null,
    lithology: "mudstone",
    covered: false,
    flooding_surface_order: 2,
    surface: null,
    note: null,
    definite_boundary: true,
    pattern: "mudstone",
    schematic: false,
    fgdc_pattern: null,
    bottom: 176.6,
    top: 177,
    tree: ["clastic", "mudstone"],
    grainsize: "ms",
    surface_type: "mfs",
    surface_order: 4,
    surface_certainty: null,
    fill_pattern: "mudstone",
  },
  {
    id: 407,
    facies: "shallow-fine-clastics",
    facies_tract: "peritidal-siliciclastic",
    section_id: "J",
    facies_color: "#4db6ac",
    lithology: "sandstone",
    covered: false,
    flooding_surface_order: 0,
    surface: 12,
    note: "Bottom Tsams B sequence boundary",
    definite_boundary: true,
    pattern: "sandstone",
    schematic: false,
    fgdc_pattern: null,
    bottom: 177,
    top: 186,
    tree: ["clastic", "sandstone"],
    grainsize: "f",
    surface_type: "sb",
    surface_order: 1,
    surface_certainty: null,
    fill_pattern: "sandstone",
  },
  {
    id: 408,
    facies: "fine-clastics",
    facies_tract: null,
    section_id: "J",
    facies_color: "#dcedc8",
    lithology: "siltstone",
    covered: false,
    flooding_surface_order: 4,
    surface: 22,
    note: "Tsams B Middle",
    definite_boundary: true,
    pattern: "siltstone",
    schematic: false,
    fgdc_pattern: null,
    bottom: 186,
    top: 187.8,
    tree: ["clastic", "siltstone"],
    grainsize: "s",
    surface_type: "mfs",
    surface_order: 2,
    surface_certainty: null,
    fill_pattern: "siltstone",
  },
  {
    id: 409,
    facies: "shallow-fine-clastics",
    facies_tract: null,
    section_id: "J",
    facies_color: "#4db6ac",
    lithology: "sandstone",
    covered: false,
    flooding_surface_order: null,
    surface: null,
    note: null,
    definite_boundary: true,
    pattern: "sandstone",
    schematic: false,
    fgdc_pattern: null,
    bottom: 187.8,
    top: 196.5,
    tree: ["clastic", "sandstone"],
    grainsize: "f",
    surface_type: "sb",
    surface_order: 2,
    surface_certainty: null,
    fill_pattern: "sandstone",
  },
  {
    id: 410,
    facies: "fine-clastics",
    facies_tract: null,
    section_id: "J",
    facies_color: "#dcedc8",
    lithology: "siltstone",
    covered: false,
    flooding_surface_order: 4,
    surface: null,
    note: null,
    definite_boundary: true,
    pattern: "siltstone",
    schematic: false,
    fgdc_pattern: null,
    bottom: 196.5,
    top: 197,
    tree: ["clastic", "siltstone"],
    grainsize: "s",
    surface_type: "mfs",
    surface_order: 3,
    surface_certainty: null,
    fill_pattern: "siltstone",
  },
  {
    id: 411,
    facies: "carbonate-mudstone",
    facies_tract: null,
    section_id: "J",
    facies_color: "#bbdefb",
    lithology: "dolomite",
    covered: false,
    flooding_surface_order: null,
    surface: null,
    note: null,
    definite_boundary: true,
    pattern: "dolomite",
    schematic: false,
    fgdc_pattern: null,
    bottom: 197,
    top: 197.6,
    tree: ["carbonate", "dolomite"],
    grainsize: "ms",
    surface_type: null,
    surface_order: null,
    surface_certainty: null,
    fill_pattern: "dolomite",
  },
  {
    id: 412,
    facies: "fine-clastics",
    facies_tract: null,
    section_id: "J",
    facies_color: "#dcedc8",
    lithology: "shale",
    covered: false,
    flooding_surface_order: 2,
    surface: 8,
    note: "Top of Tsams B",
    definite_boundary: true,
    pattern: "shale",
    schematic: false,
    fgdc_pattern: null,
    bottom: 197.6,
    top: 199,
    tree: ["clastic", "shale"],
    grainsize: "s",
    surface_type: "mfs",
    surface_order: 1,
    surface_certainty: null,
    fill_pattern: "shale",
  },
  {
    id: 413,
    facies: "carbonate-mudstone",
    facies_tract: "restricted-subtidal",
    section_id: "J",
    facies_color: "#bbdefb",
    lithology: "dolomite",
    covered: false,
    flooding_surface_order: null,
    surface: null,
    note: null,
    definite_boundary: true,
    pattern: "dolomite",
    schematic: false,
    fgdc_pattern: null,
    bottom: 199,
    top: 223,
    tree: ["carbonate", "dolomite"],
    grainsize: "ms",
    surface_type: null,
    surface_order: null,
    surface_certainty: null,
    fill_pattern: "dolomite",
  },
  {
    id: 557,
    facies: "intraclast-grainstone",
    facies_tract: null,
    section_id: "J",
    facies_color: "#7986cb",
    lithology: "dolomite",
    covered: false,
    flooding_surface_order: -3,
    surface: 31,
    note: "Tsams C Major Sequence Boundary",
    definite_boundary: false,
    pattern: "dolomite",
    schematic: false,
    fgdc_pattern: null,
    bottom: 223,
    top: 230,
    tree: ["carbonate", "dolomite"],
    grainsize: "m",
    surface_type: "sb",
    surface_order: 0,
    surface_certainty: 7,
    fill_pattern: "dolomite",
  },
  {
    id: 414,
    facies: "shallow-fine-clastics",
    facies_tract: "shoreface-alluvial-siliciclastic",
    section_id: "J",
    facies_color: "#4db6ac",
    lithology: "sandstone",
    covered: false,
    flooding_surface_order: -3,
    surface: null,
    note: null,
    definite_boundary: true,
    pattern: "sandstone",
    schematic: false,
    fgdc_pattern: null,
    bottom: 230,
    top: 232,
    tree: ["clastic", "sandstone"],
    grainsize: "c",
    surface_type: "sb",
    surface_order: 3,
    surface_certainty: 9,
    fill_pattern: "sandstone",
  },
  {
    id: 415,
    facies: "carbonate-mudstone",
    facies_tract: "restricted-subtidal",
    section_id: "J",
    facies_color: "#bbdefb",
    lithology: "dolomite",
    covered: false,
    flooding_surface_order: 3,
    surface: 34,
    note: "Tsams C upper flooding surface",
    definite_boundary: true,
    pattern: "dolomite",
    schematic: false,
    fgdc_pattern: null,
    bottom: 232,
    top: 235.6,
    tree: ["carbonate", "dolomite"],
    grainsize: "ms",
    surface_type: "mfs",
    surface_order: 1,
    surface_certainty: 9,
    fill_pattern: "dolomite",
  },
  {
    id: 559,
    facies: "intraclast-grainstone",
    facies_tract: "shoal-shoreface",
    section_id: "J",
    facies_color: "#7986cb",
    lithology: "dolomite",
    covered: false,
    flooding_surface_order: -3,
    surface: null,
    note: null,
    definite_boundary: true,
    pattern: "dolomite",
    schematic: false,
    fgdc_pattern: null,
    bottom: 235.6,
    top: 242.5,
    tree: ["carbonate", "dolomite"],
    grainsize: "f",
    surface_type: "sb",
    surface_order: 2,
    surface_certainty: null,
    fill_pattern: "dolomite",
  },
  {
    id: 558,
    facies: "knobbly-stromatolites",
    facies_tract: "exposure",
    section_id: "J",
    facies_color: "#ff7701",
    lithology: "dolomite",
    covered: false,
    flooding_surface_order: -3,
    surface: null,
    note: null,
    definite_boundary: true,
    pattern: "dolomite",
    schematic: false,
    fgdc_pattern: null,
    bottom: 242.5,
    top: 251,
    tree: ["carbonate", "dolomite"],
    grainsize: "ms",
    surface_type: "sb",
    surface_order: 1,
    surface_certainty: null,
    fill_pattern: "dolomite",
  },
  {
    id: 416,
    facies: "fine-clastics",
    facies_tract: "marine-siliciclastic",
    section_id: "J",
    facies_color: "#dcedc8",
    lithology: "siltstone",
    covered: true,
    flooding_surface_order: 1,
    surface: 1,
    note: "Top of Tsams C",
    definite_boundary: true,
    pattern: "siltstone",
    schematic: false,
    fgdc_pattern: null,
    bottom: 251,
    top: 251.5,
    tree: ["clastic", "siltstone"],
    grainsize: "s",
    surface_type: "mfs",
    surface_order: 0,
    surface_certainty: null,
    fill_pattern: "siltstone",
  },
  {
    id: 417,
    facies: null,
    facies_tract: null,
    section_id: "J",
    facies_color: null,
    lithology: "siltstone",
    covered: false,
    flooding_surface_order: null,
    surface: null,
    note: null,
    definite_boundary: true,
    pattern: "siltstone",
    schematic: false,
    fgdc_pattern: null,
    bottom: 251.5,
    top: 279,
    tree: ["clastic", "siltstone"],
    grainsize: "s",
    surface_type: null,
    surface_order: 0,
    surface_certainty: null,
    fill_pattern: "624",
  },
  {
    id: 418,
    facies: null,
    facies_tract: null,
    section_id: "J",
    facies_color: null,
    lithology: "dolomite",
    covered: false,
    flooding_surface_order: null,
    surface: null,
    note: null,
    definite_boundary: true,
    pattern: "dolomite",
    schematic: false,
    fgdc_pattern: null,
    bottom: 279,
    top: 279.4,
    tree: ["carbonate", "dolomite"],
    grainsize: "ms",
    surface_type: null,
    surface_order: null,
    surface_certainty: null,
    fill_pattern: "dolomite",
  },
  {
    id: 419,
    facies: null,
    facies_tract: null,
    section_id: "J",
    facies_color: null,
    lithology: "siltstone",
    covered: false,
    flooding_surface_order: 5,
    surface: null,
    note: null,
    definite_boundary: true,
    pattern: "siltstone",
    schematic: false,
    fgdc_pattern: null,
    bottom: 279.4,
    top: 281.7,
    tree: ["clastic", "siltstone"],
    grainsize: "s",
    surface_type: "mfs",
    surface_order: 4,
    surface_certainty: null,
    fill_pattern: "624",
  },
  {
    id: 420,
    facies: null,
    facies_tract: null,
    section_id: "J",
    facies_color: null,
    lithology: "dolomite",
    covered: false,
    flooding_surface_order: null,
    surface: null,
    note: null,
    definite_boundary: true,
    pattern: "dolomite",
    schematic: false,
    fgdc_pattern: null,
    bottom: 281.7,
    top: 281.9,
    tree: ["carbonate", "dolomite"],
    grainsize: "ms",
    surface_type: null,
    surface_order: null,
    surface_certainty: null,
    fill_pattern: "dolomite",
  },
  {
    id: 421,
    facies: null,
    facies_tract: null,
    section_id: "J",
    facies_color: null,
    lithology: "siltstone",
    covered: false,
    flooding_surface_order: 5,
    surface: null,
    note: null,
    definite_boundary: true,
    pattern: "siltstone",
    schematic: false,
    fgdc_pattern: null,
    bottom: 281.9,
    top: 290.3,
    tree: ["clastic", "siltstone"],
    grainsize: "s",
    surface_type: "mfs",
    surface_order: 4,
    surface_certainty: null,
    fill_pattern: "624",
  },
  {
    id: 422,
    facies: "shallow-fine-clastics",
    facies_tract: "restricted-subtidal",
    section_id: "J",
    facies_color: "#4db6ac",
    lithology: "sandstone",
    covered: false,
    flooding_surface_order: null,
    surface: null,
    note: null,
    definite_boundary: true,
    pattern: "sandstone",
    schematic: false,
    fgdc_pattern: null,
    bottom: 290.3,
    top: 291,
    tree: ["clastic", "sandstone"],
    grainsize: "vf",
    surface_type: "sb",
    surface_order: 1,
    surface_certainty: null,
    fill_pattern: "sandstone",
  },
  {
    id: 423,
    facies: "fine-clastics",
    facies_tract: null,
    section_id: "J",
    facies_color: "#dcedc8",
    lithology: "siltstone",
    covered: false,
    flooding_surface_order: 4,
    surface: 27,
    note: "Lower Lemoenputs internal flooding surface",
    definite_boundary: true,
    pattern: "siltstone",
    schematic: false,
    fgdc_pattern: null,
    bottom: 291,
    top: 292,
    tree: ["clastic", "siltstone"],
    grainsize: "s",
    surface_type: "mfs",
    surface_order: 3,
    surface_certainty: 5,
    fill_pattern: "siltstone",
  },
  {
    id: 424,
    facies: "carbonate-mudstone",
    facies_tract: null,
    section_id: "J",
    facies_color: "#bbdefb",
    lithology: "lime mudstone",
    covered: false,
    flooding_surface_order: null,
    surface: null,
    note: null,
    definite_boundary: true,
    pattern: "lime_mudstone",
    schematic: false,
    fgdc_pattern: null,
    bottom: 292,
    top: 294,
    tree: ["carbonate", "limestone", "lime mudstone"],
    grainsize: "ms",
    surface_type: null,
    surface_order: null,
    surface_certainty: null,
    fill_pattern: "lime_mudstone",
  },
  {
    id: 425,
    facies: null,
    facies_tract: null,
    section_id: "J",
    facies_color: null,
    lithology: "dolomite",
    covered: false,
    flooding_surface_order: null,
    surface: null,
    note: null,
    definite_boundary: true,
    pattern: "dolomite",
    schematic: false,
    fgdc_pattern: null,
    bottom: 294,
    top: 295.3,
    tree: ["carbonate", "dolomite"],
    grainsize: "ms",
    surface_type: null,
    surface_order: null,
    surface_certainty: null,
    fill_pattern: "dolomite",
  },
  {
    id: 426,
    facies: "fine-clastics",
    facies_tract: null,
    section_id: "J",
    facies_color: "#dcedc8",
    lithology: "siltstone",
    covered: false,
    flooding_surface_order: null,
    surface: null,
    note: null,
    definite_boundary: true,
    pattern: "siltstone",
    schematic: false,
    fgdc_pattern: null,
    bottom: 295.3,
    top: 296,
    tree: ["clastic", "siltstone"],
    grainsize: "s",
    surface_type: null,
    surface_order: null,
    surface_certainty: null,
    fill_pattern: "624",
  },
  {
    id: 427,
    facies: "carbonate-mudstone",
    facies_tract: null,
    section_id: "J",
    facies_color: "#bbdefb",
    lithology: "limestone",
    covered: false,
    flooding_surface_order: null,
    surface: null,
    note: null,
    definite_boundary: true,
    pattern: "limestone",
    schematic: false,
    fgdc_pattern: null,
    bottom: 296,
    top: 297.4,
    tree: ["carbonate", "limestone"],
    grainsize: "ms",
    surface_type: null,
    surface_order: null,
    surface_certainty: null,
    fill_pattern: "limestone",
  },
  {
    id: 428,
    facies: "fine-clastics",
    facies_tract: null,
    section_id: "J",
    facies_color: "#dcedc8",
    lithology: "siltstone",
    covered: false,
    flooding_surface_order: null,
    surface: null,
    note: null,
    definite_boundary: true,
    pattern: "siltstone",
    schematic: false,
    fgdc_pattern: null,
    bottom: 297.4,
    top: 299.7,
    tree: ["clastic", "siltstone"],
    grainsize: "s",
    surface_type: "mfs",
    surface_order: 4,
    surface_certainty: 7,
    fill_pattern: "siltstone",
  },
  {
    id: 429,
    facies: null,
    facies_tract: null,
    section_id: "J",
    facies_color: null,
    lithology: "lime mudstone",
    covered: false,
    flooding_surface_order: 5,
    surface: null,
    note: null,
    definite_boundary: true,
    pattern: "lime_mudstone",
    schematic: false,
    fgdc_pattern: null,
    bottom: 299.7,
    top: 300,
    tree: ["carbonate", "limestone", "lime mudstone"],
    grainsize: "s",
    surface_type: null,
    surface_order: null,
    surface_certainty: null,
    fill_pattern: "lime_mudstone",
  },
  {
    id: 430,
    facies: "coarse-clastics",
    facies_tract: "shoal-shoreface",
    section_id: "J",
    facies_color: "#006064",
    lithology: "sandstone",
    covered: false,
    flooding_surface_order: -1,
    surface: 16,
    note: "Base of Lemoenputs Horizon A",
    definite_boundary: true,
    pattern: "sandstone",
    schematic: false,
    fgdc_pattern: null,
    bottom: 300,
    top: 303,
    tree: ["clastic", "sandstone"],
    grainsize: "f",
    surface_type: "sb",
    surface_order: 1,
    surface_certainty: null,
    fill_pattern: "sandstone",
  },
  {
    id: 431,
    facies: "hcs-grainstone",
    facies_tract: "restricted-subtidal",
    section_id: "J",
    facies_color: "#673ab7",
    lithology: "limestone",
    covered: false,
    flooding_surface_order: 4,
    surface: null,
    note: null,
    definite_boundary: true,
    pattern: "limestone",
    schematic: false,
    fgdc_pattern: null,
    bottom: 303,
    top: 304,
    tree: ["carbonate", "limestone"],
    grainsize: "m",
    surface_type: "mfs",
    surface_order: 2,
    surface_certainty: null,
    fill_pattern: "limestone",
  },
  {
    id: 432,
    facies: "fine-clastics",
    facies_tract: null,
    section_id: "J",
    facies_color: "#dcedc8",
    lithology: "shale",
    covered: true,
    flooding_surface_order: null,
    surface: null,
    note: null,
    definite_boundary: true,
    pattern: "shale",
    schematic: false,
    fgdc_pattern: null,
    bottom: 304,
    top: 304.4,
    tree: ["clastic", "shale"],
    grainsize: "s",
    surface_type: null,
    surface_order: null,
    surface_certainty: null,
    fill_pattern: "shale",
  },
  {
    id: 433,
    facies: "hcs-grainstone",
    facies_tract: null,
    section_id: "J",
    facies_color: "#673ab7",
    lithology: "dolomite",
    covered: false,
    flooding_surface_order: null,
    surface: null,
    note: null,
    definite_boundary: true,
    pattern: "dolomite",
    schematic: false,
    fgdc_pattern: null,
    bottom: 304.4,
    top: 305.4,
    tree: ["carbonate", "dolomite"],
    grainsize: "m",
    surface_type: null,
    surface_order: null,
    surface_certainty: null,
    fill_pattern: "dolomite",
  },
  {
    id: 434,
    facies: "fine-clastics",
    facies_tract: null,
    section_id: "J",
    facies_color: "#dcedc8",
    lithology: "shale",
    covered: true,
    flooding_surface_order: null,
    surface: null,
    note: null,
    definite_boundary: true,
    pattern: "shale",
    schematic: false,
    fgdc_pattern: null,
    bottom: 305.4,
    top: 306.2,
    tree: ["clastic", "shale"],
    grainsize: "s",
    surface_type: null,
    surface_order: null,
    surface_certainty: null,
    fill_pattern: "shale",
  },
  {
    id: 435,
    facies: "coarse-clastics",
    facies_tract: "shoal-shoreface",
    section_id: "J",
    facies_color: "#006064",
    lithology: "sandstone",
    covered: false,
    flooding_surface_order: null,
    surface: null,
    note: null,
    definite_boundary: true,
    pattern: "sandstone",
    schematic: false,
    fgdc_pattern: null,
    bottom: 306.2,
    top: 306.8,
    tree: ["clastic", "sandstone"],
    grainsize: "m",
    surface_type: "sb",
    surface_order: 2,
    surface_certainty: null,
    fill_pattern: "sandstone",
  },
  {
    id: 570,
    facies: "carbonate-mudstone",
    facies_tract: "peritidal",
    section_id: "J",
    facies_color: "#bbdefb",
    lithology: "limestone",
    covered: false,
    flooding_surface_order: -3,
    surface: null,
    note: null,
    definite_boundary: true,
    pattern: "limestone",
    schematic: false,
    fgdc_pattern: null,
    bottom: 306.8,
    top: 308,
    tree: ["carbonate", "limestone"],
    grainsize: "ms",
    surface_type: "sb",
    surface_order: 3,
    surface_certainty: null,
    fill_pattern: "limestone",
  },
  {
    id: 436,
    facies: "coarse-clastics",
    facies_tract: "exposure",
    section_id: "J",
    facies_color: "#006064",
    lithology: "sandstone",
    covered: false,
    flooding_surface_order: -5,
    surface: null,
    note: null,
    definite_boundary: true,
    pattern: "sandstone",
    schematic: false,
    fgdc_pattern: null,
    bottom: 308,
    top: 311,
    tree: ["clastic", "sandstone"],
    grainsize: "m",
    surface_type: "sb",
    surface_order: 3,
    surface_certainty: null,
    fill_pattern: "sandstone",
  },
  {
    id: 437,
    facies: "fine-clastics",
    facies_tract: "peritidal",
    section_id: "J",
    facies_color: "#dcedc8",
    lithology: "siltstone",
    covered: true,
    flooding_surface_order: 3,
    surface: null,
    note: null,
    definite_boundary: true,
    pattern: "siltstone",
    schematic: false,
    fgdc_pattern: null,
    bottom: 311,
    top: 313,
    tree: ["clastic", "siltstone"],
    grainsize: "s",
    surface_type: "mfs",
    surface_order: 3,
    surface_certainty: null,
    fill_pattern: "624",
  },
  {
    id: 438,
    facies: "coarse-clastics",
    facies_tract: "peritidal",
    section_id: "J",
    facies_color: "#006064",
    lithology: "sandstone",
    covered: false,
    flooding_surface_order: -5,
    surface: null,
    note: null,
    definite_boundary: true,
    pattern: "sandstone",
    schematic: false,
    fgdc_pattern: null,
    bottom: 313,
    top: 313.5,
    tree: ["clastic", "sandstone"],
    grainsize: "m",
    surface_type: "sb",
    surface_order: 2,
    surface_certainty: null,
    fill_pattern: "sandstone",
  },
  {
    id: 439,
    facies: "carbonate-mudstone",
    facies_tract: null,
    section_id: "J",
    facies_color: "#bbdefb",
    lithology: "dolomite",
    covered: false,
    flooding_surface_order: 3,
    surface: 10,
    note: "Top of Lemoenputs Horizon A",
    definite_boundary: true,
    pattern: "dolomite",
    schematic: false,
    fgdc_pattern: null,
    bottom: 313.5,
    top: 315,
    tree: ["carbonate", "dolomite"],
    grainsize: "ms",
    surface_type: null,
    surface_order: 2,
    surface_certainty: null,
    fill_pattern: "dolomite",
  },
  {
    id: 440,
    facies: "fine-clastics",
    facies_tract: null,
    section_id: "J",
    facies_color: "#dcedc8",
    lithology: "siltstone",
    covered: false,
    flooding_surface_order: 3,
    surface: null,
    note: null,
    definite_boundary: true,
    pattern: "siltstone",
    schematic: false,
    fgdc_pattern: null,
    bottom: 315,
    top: 317,
    tree: ["clastic", "siltstone"],
    grainsize: "s",
    surface_type: "mfs",
    surface_order: 1,
    surface_certainty: null,
    fill_pattern: "624",
  },
  {
    id: 441,
    facies: null,
    facies_tract: null,
    section_id: "J",
    facies_color: null,
    lithology: "siltstone",
    covered: true,
    flooding_surface_order: null,
    surface: null,
    note: null,
    definite_boundary: true,
    pattern: "siltstone",
    schematic: false,
    fgdc_pattern: null,
    bottom: 317,
    top: 322,
    tree: ["clastic", "siltstone"],
    grainsize: "s",
    surface_type: null,
    surface_order: null,
    surface_certainty: null,
    fill_pattern: "624",
  },
  {
    id: 442,
    facies: null,
    facies_tract: null,
    section_id: "J",
    facies_color: null,
    lithology: "siltstone",
    covered: false,
    flooding_surface_order: null,
    surface: null,
    note: null,
    definite_boundary: true,
    pattern: "siltstone",
    schematic: false,
    fgdc_pattern: null,
    bottom: 322,
    top: 323,
    tree: ["clastic", "siltstone"],
    grainsize: "s",
    surface_type: null,
    surface_order: null,
    surface_certainty: null,
    fill_pattern: "siltstone",
  },
  {
    id: 443,
    facies: "knobbly-stromatolites",
    facies_tract: "exposure",
    section_id: "J",
    facies_color: "#ff7701",
    lithology: "lime mudstone",
    covered: false,
    flooding_surface_order: -3,
    surface: null,
    note: null,
    definite_boundary: true,
    pattern: "lime_mudstone",
    schematic: false,
    fgdc_pattern: null,
    bottom: 323,
    top: 323.5,
    tree: ["carbonate", "limestone", "lime mudstone"],
    grainsize: "ms",
    surface_type: "sb",
    surface_order: 0,
    surface_certainty: null,
    fill_pattern: "lime_mudstone",
  },
  {
    id: 444,
    facies: null,
    facies_tract: null,
    section_id: "J",
    facies_color: null,
    lithology: "limestone",
    covered: false,
    flooding_surface_order: null,
    surface: 17,
    note: "Base of Lemoenputs Ooid grainstone",
    definite_boundary: true,
    pattern: "limestone",
    schematic: false,
    fgdc_pattern: null,
    bottom: 323.5,
    top: 325,
    tree: ["carbonate", "limestone"],
    grainsize: "ms",
    surface_type: null,
    surface_order: 1,
    surface_certainty: 8,
    fill_pattern: "limestone",
  },
  {
    id: 560,
    facies: "mixed-grainstone",
    facies_tract: "restricted-subtidal",
    section_id: "J",
    facies_color: "#4a148c",
    lithology: "limestone",
    covered: false,
    flooding_surface_order: 3,
    surface: null,
    note: null,
    definite_boundary: true,
    pattern: "limestone",
    schematic: false,
    fgdc_pattern: null,
    bottom: 325,
    top: 327,
    tree: ["carbonate", "limestone"],
    grainsize: "m",
    surface_type: "mfs",
    surface_order: 2,
    surface_certainty: null,
    fill_pattern: "limestone",
  },
  {
    id: 561,
    facies: "knobbly-stromatolites",
    facies_tract: "exposure",
    section_id: "J",
    facies_color: "#ff7701",
    lithology: "limestone",
    covered: false,
    flooding_surface_order: -3,
    surface: null,
    note: null,
    definite_boundary: true,
    pattern: "limestone",
    schematic: false,
    fgdc_pattern: null,
    bottom: 327,
    top: 329,
    tree: ["carbonate", "limestone"],
    grainsize: "ms",
    surface_type: "sb",
    surface_order: 2,
    surface_certainty: null,
    fill_pattern: "limestone",
  },
  {
    id: 550,
    facies: "mixed-grainstone",
    facies_tract: "restricted-subtidal",
    section_id: "J",
    facies_color: "#4a148c",
    lithology: "limestone",
    covered: false,
    flooding_surface_order: 3,
    surface: null,
    note: null,
    definite_boundary: true,
    pattern: "limestone",
    schematic: false,
    fgdc_pattern: null,
    bottom: 329,
    top: 332.73,
    tree: ["carbonate", "limestone"],
    grainsize: "m",
    surface_type: "mfs",
    surface_order: 2,
    surface_certainty: null,
    fill_pattern: "limestone",
  },
  {
    id: 613,
    facies: "hcs-grainstone",
    facies_tract: "restricted-subtidal",
    section_id: "J",
    facies_color: "#673ab7",
    lithology: null,
    covered: false,
    flooding_surface_order: null,
    surface: null,
    note: null,
    definite_boundary: true,
    pattern: null,
    schematic: false,
    fgdc_pattern: null,
    bottom: 332.73,
    top: 336.78,
    tree: null,
    grainsize: null,
    surface_type: null,
    surface_order: null,
    surface_certainty: null,
    fill_pattern: null,
  },
  {
    id: 614,
    facies: "intraclast-grainstone",
    facies_tract: null,
    section_id: "J",
    facies_color: "#7986cb",
    lithology: null,
    covered: false,
    flooding_surface_order: null,
    surface: null,
    note: null,
    definite_boundary: true,
    pattern: null,
    schematic: false,
    fgdc_pattern: null,
    bottom: 336.78,
    top: 339,
    tree: null,
    grainsize: null,
    surface_type: null,
    surface_order: null,
    surface_certainty: null,
    fill_pattern: null,
  },
  {
    id: 562,
    facies: "knobbly-stromatolites",
    facies_tract: "exposure",
    section_id: "J",
    facies_color: "#ff7701",
    lithology: "limestone",
    covered: false,
    flooding_surface_order: -3,
    surface: null,
    note: null,
    definite_boundary: true,
    pattern: "limestone",
    schematic: false,
    fgdc_pattern: null,
    bottom: 339,
    top: 340.5,
    tree: ["carbonate", "limestone"],
    grainsize: "ms",
    surface_type: "sb",
    surface_order: 2,
    surface_certainty: null,
    fill_pattern: "limestone",
  },
  {
    id: 445,
    facies: "fine-clastics",
    facies_tract: "marine-siliciclastic",
    section_id: "J",
    facies_color: "#dcedc8",
    lithology: "sandstone",
    covered: false,
    flooding_surface_order: 3,
    surface: 3,
    note: "Top of Lemoenputs Ooid grainstone",
    definite_boundary: true,
    pattern: "sandstone",
    schematic: false,
    fgdc_pattern: null,
    bottom: 340.5,
    top: 342,
    tree: ["clastic", "sandstone"],
    grainsize: "vf",
    surface_type: "mfs",
    surface_order: 1,
    surface_certainty: null,
    fill_pattern: "sandstone",
  },
  {
    id: 446,
    facies: "fine-clastics",
    facies_tract: null,
    section_id: "J",
    facies_color: "#dcedc8",
    lithology: "siltstone",
    covered: true,
    flooding_surface_order: 3,
    surface: null,
    note: null,
    definite_boundary: true,
    pattern: "siltstone",
    schematic: false,
    fgdc_pattern: null,
    bottom: 342,
    top: 344,
    tree: ["clastic", "siltstone"],
    grainsize: "s",
    surface_type: "mfs",
    surface_order: 4,
    surface_certainty: null,
    fill_pattern: "siltstone",
  },
  {
    id: 447,
    facies: null,
    facies_tract: null,
    section_id: "J",
    facies_color: null,
    lithology: "siltstone",
    covered: false,
    flooding_surface_order: null,
    surface: null,
    note: null,
    definite_boundary: true,
    pattern: "siltstone",
    schematic: false,
    fgdc_pattern: null,
    bottom: 344,
    top: 346,
    tree: ["clastic", "siltstone"],
    grainsize: "s",
    surface_type: null,
    surface_order: null,
    surface_certainty: null,
    fill_pattern: "siltstone",
  },
  {
    id: 448,
    facies: null,
    facies_tract: null,
    section_id: "J",
    facies_color: null,
    lithology: "siltstone",
    covered: true,
    flooding_surface_order: null,
    surface: null,
    note: null,
    definite_boundary: true,
    pattern: "siltstone",
    schematic: false,
    fgdc_pattern: null,
    bottom: 346,
    top: 348,
    tree: ["clastic", "siltstone"],
    grainsize: "s",
    surface_type: null,
    surface_order: null,
    surface_certainty: null,
    fill_pattern: "siltstone",
  },
  {
    id: 449,
    facies: null,
    facies_tract: null,
    section_id: "J",
    facies_color: null,
    lithology: "sandstone",
    covered: false,
    flooding_surface_order: null,
    surface: null,
    note: null,
    definite_boundary: true,
    pattern: "sandstone",
    schematic: false,
    fgdc_pattern: null,
    bottom: 348,
    top: 350,
    tree: ["clastic", "sandstone"],
    grainsize: "vf",
    surface_type: null,
    surface_order: null,
    surface_certainty: null,
    fill_pattern: "sandstone",
  },
  {
    id: 450,
    facies: null,
    facies_tract: null,
    section_id: "J",
    facies_color: null,
    lithology: "siltstone",
    covered: true,
    flooding_surface_order: null,
    surface: null,
    note: null,
    definite_boundary: true,
    pattern: "siltstone",
    schematic: false,
    fgdc_pattern: null,
    bottom: 350,
    top: 355,
    tree: ["clastic", "siltstone"],
    grainsize: "vf",
    surface_type: null,
    surface_order: null,
    surface_certainty: null,
    fill_pattern: "siltstone",
  },
  {
    id: 451,
    facies: null,
    facies_tract: null,
    section_id: "J",
    facies_color: null,
    lithology: "sandstone",
    covered: false,
    flooding_surface_order: null,
    surface: null,
    note: null,
    definite_boundary: true,
    pattern: "sandstone",
    schematic: false,
    fgdc_pattern: null,
    bottom: 355,
    top: 358,
    tree: ["clastic", "sandstone"],
    grainsize: "vf",
    surface_type: null,
    surface_order: null,
    surface_certainty: null,
    fill_pattern: "611",
  },
  {
    id: 452,
    facies: null,
    facies_tract: null,
    section_id: "J",
    facies_color: null,
    lithology: "siltstone",
    covered: false,
    flooding_surface_order: null,
    surface: null,
    note: null,
    definite_boundary: true,
    pattern: "siltstone",
    schematic: false,
    fgdc_pattern: null,
    bottom: 358,
    top: 358.4,
    tree: ["clastic", "siltstone"],
    grainsize: "vf",
    surface_type: null,
    surface_order: null,
    surface_certainty: null,
    fill_pattern: "siltstone",
  },
  {
    id: 453,
    facies: "fine-clastics",
    facies_tract: null,
    section_id: "J",
    facies_color: "#dcedc8",
    lithology: "sandstone",
    covered: false,
    flooding_surface_order: null,
    surface: null,
    note: null,
    definite_boundary: true,
    pattern: "sandstone",
    schematic: false,
    fgdc_pattern: null,
    bottom: 358.4,
    top: 364.38,
    tree: ["clastic", "sandstone"],
    grainsize: "vf",
    surface_type: null,
    surface_order: null,
    surface_certainty: null,
    fill_pattern: "sandstone",
  },
  {
    id: 594,
    facies: "fine-clastics",
    facies_tract: null,
    section_id: "J",
    facies_color: "#dcedc8",
    lithology: null,
    covered: false,
    flooding_surface_order: null,
    surface: null,
    note: null,
    definite_boundary: true,
    pattern: null,
    schematic: false,
    fgdc_pattern: null,
    bottom: 364.38,
    top: 381,
    tree: null,
    grainsize: null,
    surface_type: null,
    surface_order: null,
    surface_certainty: null,
    fill_pattern: null,
  },
  {
    id: 454,
    facies: "carbonate-mudstone",
    facies_tract: null,
    section_id: "J",
    facies_color: "#bbdefb",
    lithology: "limestone",
    covered: false,
    flooding_surface_order: 5,
    surface: null,
    note: null,
    definite_boundary: true,
    pattern: "limestone",
    schematic: false,
    fgdc_pattern: null,
    bottom: 381,
    top: 381.3,
    tree: ["carbonate", "limestone"],
    grainsize: "ms",
    surface_type: "mfs",
    surface_order: 3,
    surface_certainty: null,
    fill_pattern: "limestone",
  },
  {
    id: 455,
    facies: "fine-clastics",
    facies_tract: null,
    section_id: "J",
    facies_color: "#dcedc8",
    lithology: "sandstone",
    covered: false,
    flooding_surface_order: null,
    surface: null,
    note: null,
    definite_boundary: true,
    pattern: "sandstone",
    schematic: false,
    fgdc_pattern: null,
    bottom: 381.3,
    top: 385,
    tree: ["clastic", "sandstone"],
    grainsize: "vf",
    surface_type: null,
    surface_order: null,
    surface_certainty: null,
    fill_pattern: "624",
  },
  {
    id: 456,
    facies: "carbonate-mudstone",
    facies_tract: null,
    section_id: "J",
    facies_color: "#bbdefb",
    lithology: "limestone",
    covered: false,
    flooding_surface_order: 5,
    surface: null,
    note: null,
    definite_boundary: true,
    pattern: "limestone",
    schematic: false,
    fgdc_pattern: null,
    bottom: 385,
    top: 385.22,
    tree: ["carbonate", "limestone"],
    grainsize: "ms",
    surface_type: "mfs",
    surface_order: 3,
    surface_certainty: null,
    fill_pattern: "limestone",
  },
  {
    id: 651,
    facies: "fine-clastics",
    facies_tract: null,
    section_id: "J",
    facies_color: "#dcedc8",
    lithology: "sandstone",
    covered: false,
    flooding_surface_order: null,
    surface: null,
    note: null,
    definite_boundary: true,
    pattern: "sandstone",
    schematic: false,
    fgdc_pattern: null,
    bottom: 385.22,
    top: 392,
    tree: ["clastic", "sandstone"],
    grainsize: "f",
    surface_type: null,
    surface_order: null,
    surface_certainty: null,
    fill_pattern: "sandstone",
  },
  {
    id: 458,
    facies: "carbonate-mudstone",
    facies_tract: "restricted-subtidal",
    section_id: "J",
    facies_color: "#bbdefb",
    lithology: "limestone",
    covered: false,
    flooding_surface_order: null,
    surface: 14,
    note: "Base of Lemoenputs Hummocky Grainstone",
    definite_boundary: true,
    pattern: "limestone",
    schematic: false,
    fgdc_pattern: null,
    bottom: 392,
    top: 393,
    tree: ["carbonate", "limestone"],
    grainsize: "m",
    surface_type: "sb",
    surface_order: 1,
    surface_certainty: null,
    fill_pattern: "limestone",
  },
  {
    id: 563,
    facies: "hcs-grainstone",
    facies_tract: "shoal-shoreface",
    section_id: "J",
    facies_color: "#673ab7",
    lithology: "limestone",
    covered: false,
    flooding_surface_order: null,
    surface: null,
    note: null,
    definite_boundary: true,
    pattern: "limestone",
    schematic: false,
    fgdc_pattern: null,
    bottom: 393,
    top: 396.7,
    tree: ["carbonate", "limestone"],
    grainsize: "m",
    surface_type: null,
    surface_order: null,
    surface_certainty: null,
    fill_pattern: "limestone",
  },
  {
    id: 459,
    facies: "fine-clastics",
    facies_tract: "marine-siliciclastic",
    section_id: "J",
    facies_color: "#dcedc8",
    lithology: "shale",
    covered: false,
    flooding_surface_order: 2,
    surface: 7,
    note: "Last Lemoenputs",
    definite_boundary: true,
    pattern: "shale",
    schematic: false,
    fgdc_pattern: null,
    bottom: 396.7,
    top: 398,
    tree: ["clastic", "shale"],
    grainsize: "s",
    surface_type: "mfs",
    surface_order: 1,
    surface_certainty: null,
    fill_pattern: "624",
  },
  {
    id: 460,
    facies: null,
    facies_tract: null,
    section_id: "J",
    facies_color: null,
    lithology: "limestone",
    covered: false,
    flooding_surface_order: null,
    surface: null,
    note: null,
    definite_boundary: true,
    pattern: "limestone",
    schematic: false,
    fgdc_pattern: null,
    bottom: 398,
    top: 398.3,
    tree: ["carbonate", "limestone"],
    grainsize: "s",
    surface_type: null,
    surface_order: null,
    surface_certainty: null,
    fill_pattern: "limestone",
  },
  {
    id: 461,
    facies: null,
    facies_tract: "marine-siliciclastic",
    section_id: "J",
    facies_color: null,
    lithology: "shale",
    covered: false,
    flooding_surface_order: null,
    surface: null,
    note: null,
    definite_boundary: true,
    pattern: "shale",
    schematic: false,
    fgdc_pattern: null,
    bottom: 398.3,
    top: 402,
    tree: ["clastic", "shale"],
    grainsize: "s",
    surface_type: null,
    surface_order: null,
    surface_certainty: null,
    fill_pattern: "624",
  },
  {
    id: 462,
    facies: "intraclast-grainstone",
    facies_tract: "shoal-shoreface",
    section_id: "J",
    facies_color: "#7986cb",
    lithology: "limestone",
    covered: false,
    flooding_surface_order: null,
    surface: 15,
    note: "Base of Onis Formation",
    definite_boundary: true,
    pattern: "limestone",
    schematic: false,
    fgdc_pattern: null,
    bottom: 402,
    top: 406.5,
    tree: ["carbonate", "limestone"],
    grainsize: "m",
    surface_type: "sb",
    surface_order: 0,
    surface_certainty: null,
    fill_pattern: "limestone",
  },
  {
    id: 463,
    facies: "carbonate-mudstone",
    facies_tract: "restricted-subtidal",
    section_id: "J",
    facies_color: "#bbdefb",
    lithology: "limestone/dolomite",
    covered: false,
    flooding_surface_order: 4,
    surface: null,
    note: null,
    definite_boundary: false,
    pattern: "dolomite-limestone",
    schematic: false,
    fgdc_pattern: null,
    bottom: 406.5,
    top: 409.8,
    tree: ["carbonate", "limestone/dolomite"],
    grainsize: "ms",
    surface_type: "mfs",
    surface_order: 3,
    surface_certainty: null,
    fill_pattern: "dolomite-limestone",
  },
  {
    id: 564,
    facies: "intraclast-grainstone",
    facies_tract: "shoal-shoreface",
    section_id: "J",
    facies_color: "#7986cb",
    lithology: "limestone",
    covered: false,
    flooding_surface_order: null,
    surface: null,
    note: null,
    definite_boundary: true,
    pattern: "limestone",
    schematic: false,
    fgdc_pattern: null,
    bottom: 409.8,
    top: 413,
    tree: ["carbonate", "limestone"],
    grainsize: "m",
    surface_type: "sb",
    surface_order: 3,
    surface_certainty: null,
    fill_pattern: "limestone",
  },
  {
    id: 464,
    facies: null,
    facies_tract: null,
    section_id: "J",
    facies_color: null,
    lithology: "limestone",
    covered: false,
    flooding_surface_order: null,
    surface: null,
    note: null,
    definite_boundary: false,
    pattern: "limestone",
    schematic: false,
    fgdc_pattern: null,
    bottom: 413,
    top: 416.65,
    tree: ["carbonate", "limestone"],
    grainsize: "m",
    surface_type: null,
    surface_order: null,
    surface_certainty: null,
    fill_pattern: "limestone",
  },
  {
    id: 661,
    facies: "carbonate-mudstone",
    facies_tract: "peritidal",
    section_id: "J",
    facies_color: "#bbdefb",
    lithology: null,
    covered: false,
    flooding_surface_order: null,
    surface: null,
    note: null,
    definite_boundary: true,
    pattern: null,
    schematic: false,
    fgdc_pattern: null,
    bottom: 416.65,
    top: 419,
    tree: null,
    grainsize: "ms",
    surface_type: "mfs",
    surface_order: 2,
    surface_certainty: null,
    fill_pattern: null,
  },
  {
    id: 466,
    facies: null,
    facies_tract: null,
    section_id: "J",
    facies_color: null,
    lithology: "dolomite mudstone",
    covered: false,
    flooding_surface_order: null,
    surface: null,
    note: null,
    definite_boundary: false,
    pattern: "dolomite-mudstone",
    schematic: false,
    fgdc_pattern: null,
    bottom: 419,
    top: 430.5,
    tree: ["carbonate", "dolomite", "dolomite mudstone"],
    grainsize: "ms",
    surface_type: null,
    surface_order: null,
    surface_certainty: null,
    fill_pattern: "dolomite-mudstone",
  },
  {
    id: 467,
    facies: "intraclast-grainstone",
    facies_tract: "shoal-shoreface",
    section_id: "J",
    facies_color: "#7986cb",
    lithology: "limestone/dolomite",
    covered: false,
    flooding_surface_order: null,
    surface: null,
    note: null,
    definite_boundary: false,
    pattern: "dolomite-limestone",
    schematic: false,
    fgdc_pattern: null,
    bottom: 430.5,
    top: 456,
    tree: ["carbonate", "limestone/dolomite"],
    grainsize: "m",
    surface_type: "sb",
    surface_order: 2,
    surface_certainty: null,
    fill_pattern: "dolomite-limestone",
  },
  {
    id: 551,
    facies: "carbonate-mudstone",
    facies_tract: null,
    section_id: "J",
    facies_color: "#bbdefb",
    lithology: null,
    covered: false,
    flooding_surface_order: 3,
    surface: null,
    note: null,
    definite_boundary: true,
    pattern: null,
    schematic: false,
    fgdc_pattern: null,
    bottom: 456,
    top: 458,
    tree: null,
    grainsize: "ms",
    surface_type: "mfs",
    surface_order: 2,
    surface_certainty: null,
    fill_pattern: null,
  },
  {
    id: 565,
    facies: "intraclast-grainstone",
    facies_tract: null,
    section_id: "J",
    facies_color: "#7986cb",
    lithology: "limestone/dolomite",
    covered: false,
    flooding_surface_order: null,
    surface: null,
    note: null,
    definite_boundary: false,
    pattern: "dolomite-limestone",
    schematic: false,
    fgdc_pattern: null,
    bottom: 458,
    top: 466.5,
    tree: ["carbonate", "limestone/dolomite"],
    grainsize: "m",
    surface_type: null,
    surface_order: null,
    surface_certainty: null,
    fill_pattern: "dolomite-limestone",
  },
  {
    id: 721,
    facies: null,
    facies_tract: null,
    section_id: "J",
    facies_color: null,
    lithology: null,
    covered: false,
    flooding_surface_order: null,
    surface: null,
    note: null,
    definite_boundary: true,
    pattern: null,
    schematic: false,
    fgdc_pattern: null,
    bottom: 466.5,
    top: 472,
    tree: null,
    grainsize: null,
    surface_type: "sb",
    surface_order: 2,
    surface_certainty: null,
    fill_pattern: null,
  },
  {
    id: 468,
    facies: "hcs-grainstone",
    facies_tract: "shoal-shoreface",
    section_id: "J",
    facies_color: "#673ab7",
    lithology: "limestone",
    covered: false,
    flooding_surface_order: 3,
    surface: 13,
    note: "Top of Lower Onis",
    definite_boundary: false,
    pattern: "limestone",
    schematic: false,
    fgdc_pattern: null,
    bottom: 472,
    top: 480,
    tree: ["carbonate", "limestone"],
    grainsize: "m",
    surface_type: "mfs",
    surface_order: 1,
    surface_certainty: null,
    fill_pattern: "limestone",
  },
  {
    id: 469,
    facies: null,
    facies_tract: "shoal-shoreface",
    section_id: "J",
    facies_color: null,
    lithology: null,
    covered: false,
    flooding_surface_order: null,
    surface: null,
    note: null,
    definite_boundary: false,
    pattern: null,
    schematic: false,
    fgdc_pattern: null,
    bottom: 480,
    top: 481.25,
    tree: null,
    grainsize: "m",
    surface_type: null,
    surface_order: 2,
    surface_certainty: null,
    fill_pattern: null,
  },
  {
    id: 720,
    facies: "mixed-grainstone",
    facies_tract: null,
    section_id: "J",
    facies_color: "#4a148c",
    lithology: null,
    covered: false,
    flooding_surface_order: null,
    surface: null,
    note: null,
    definite_boundary: true,
    pattern: null,
    schematic: false,
    fgdc_pattern: null,
    bottom: 481.25,
    top: 489.3,
    tree: null,
    grainsize: null,
    surface_type: "mfs",
    surface_order: 3,
    surface_certainty: null,
    fill_pattern: null,
  },
  {
    id: 680,
    facies: "intraclast-grainstone",
    facies_tract: null,
    section_id: "J",
    facies_color: "#7986cb",
    lithology: null,
    covered: false,
    flooding_surface_order: 4,
    surface: 29,
    note: "Top of Middle Onis",
    definite_boundary: true,
    pattern: null,
    schematic: false,
    fgdc_pattern: null,
    bottom: 489.3,
    top: 495.33,
    tree: null,
    grainsize: null,
    surface_type: "sb",
    surface_order: 1,
    surface_certainty: 6,
    fill_pattern: null,
  },
  {
    id: 615,
    facies: null,
    facies_tract: null,
    section_id: "J",
    facies_color: null,
    lithology: null,
    covered: false,
    flooding_surface_order: 5,
    surface: null,
    note: null,
    definite_boundary: true,
    pattern: null,
    schematic: false,
    fgdc_pattern: null,
    bottom: 495.33,
    top: 508,
    tree: null,
    grainsize: null,
    surface_type: "sb",
    surface_order: 3,
    surface_certainty: null,
    fill_pattern: null,
  },
  {
    id: 566,
    facies: "intraclast-grainstone",
    facies_tract: null,
    section_id: "J",
    facies_color: "#7986cb",
    lithology: "limestone/dolomite",
    covered: false,
    flooding_surface_order: null,
    surface: null,
    note: null,
    definite_boundary: false,
    pattern: "dolomite-limestone",
    schematic: false,
    fgdc_pattern: null,
    bottom: 508,
    top: 519.75,
    tree: ["carbonate", "limestone/dolomite"],
    grainsize: null,
    surface_type: "mfs",
    surface_order: 2,
    surface_certainty: null,
    fill_pattern: "dolomite-limestone",
  },
  {
    id: 679,
    facies: null,
    facies_tract: null,
    section_id: "J",
    facies_color: null,
    lithology: null,
    covered: false,
    flooding_surface_order: 4,
    surface: null,
    note: null,
    definite_boundary: true,
    pattern: null,
    schematic: false,
    fgdc_pattern: null,
    bottom: 519.75,
    top: 540.1,
    tree: null,
    grainsize: "m",
    surface_type: null,
    surface_order: 2,
    surface_certainty: null,
    fill_pattern: null,
  },
  {
    id: 719,
    facies: null,
    facies_tract: null,
    section_id: "J",
    facies_color: null,
    lithology: null,
    covered: false,
    flooding_surface_order: null,
    surface: null,
    note: null,
    definite_boundary: true,
    pattern: null,
    schematic: false,
    fgdc_pattern: null,
    bottom: 540.1,
    top: 552,
    tree: null,
    grainsize: null,
    surface_type: null,
    surface_order: null,
    surface_certainty: null,
    fill_pattern: null,
  },
  {
    id: 703,
    facies: null,
    facies_tract: null,
    section_id: "J",
    facies_color: null,
    lithology: null,
    covered: false,
    flooding_surface_order: null,
    surface: 30,
    note: "Near top of Onis",
    definite_boundary: true,
    pattern: null,
    schematic: false,
    fgdc_pattern: null,
    bottom: 552,
    top: 556,
    tree: null,
    grainsize: null,
    surface_type: null,
    surface_order: null,
    surface_certainty: 4,
    fill_pattern: null,
  },
  {
    id: 567,
    facies: "carbonate-mudstone",
    facies_tract: "peritidal",
    section_id: "J",
    facies_color: "#bbdefb",
    lithology: "limestone/dolomite",
    covered: false,
    flooding_surface_order: 4,
    surface: null,
    note: null,
    definite_boundary: false,
    pattern: "dolomite-limestone",
    schematic: false,
    fgdc_pattern: null,
    bottom: 556,
    top: 562,
    tree: ["carbonate", "limestone/dolomite"],
    grainsize: "ms",
    surface_type: "sb",
    surface_order: 2,
    surface_certainty: null,
    fill_pattern: "dolomite-limestone",
  },
  {
    id: 470,
    facies: "fine-clastics",
    facies_tract: "marine-carbonate",
    section_id: "J",
    facies_color: "#dcedc8",
    lithology: "shale",
    covered: true,
    flooding_surface_order: 2,
    surface: 19,
    note: "Top of Onis Formation",
    definite_boundary: true,
    pattern: "shale",
    schematic: false,
    fgdc_pattern: null,
    bottom: 562,
    top: 564,
    tree: ["clastic", "shale"],
    grainsize: "s",
    surface_type: "mfs",
    surface_order: 0,
    surface_certainty: null,
    fill_pattern: "shale",
  },
  {
    id: 471,
    facies: null,
    facies_tract: null,
    section_id: "J",
    facies_color: null,
    lithology: "shale",
    covered: false,
    flooding_surface_order: null,
    surface: null,
    note: null,
    definite_boundary: false,
    pattern: "shale",
    schematic: false,
    fgdc_pattern: null,
    bottom: 564,
    top: 564.8,
    tree: ["clastic", "shale"],
    grainsize: "s",
    surface_type: null,
    surface_order: null,
    surface_certainty: null,
    fill_pattern: "shale",
  },
  {
    id: 472,
    facies: null,
    facies_tract: null,
    section_id: "J",
    facies_color: null,
    lithology: "limestone",
    covered: false,
    flooding_surface_order: null,
    surface: null,
    note: null,
    definite_boundary: true,
    pattern: "limestone",
    schematic: false,
    fgdc_pattern: null,
    bottom: 564.8,
    top: 565.1,
    tree: ["carbonate", "limestone"],
    grainsize: "ms",
    surface_type: null,
    surface_order: null,
    surface_certainty: null,
    fill_pattern: "limestone",
  },
  {
    id: 473,
    facies: null,
    facies_tract: null,
    section_id: "J",
    facies_color: null,
    lithology: "shale",
    covered: true,
    flooding_surface_order: 4,
    surface: null,
    note: null,
    definite_boundary: true,
    pattern: "shale",
    schematic: false,
    fgdc_pattern: null,
    bottom: 565.1,
    top: 567,
    tree: ["clastic", "shale"],
    grainsize: "s",
    surface_type: "mfs",
    surface_order: 3,
    surface_certainty: null,
    fill_pattern: "shale",
  },
  {
    id: 474,
    facies: null,
    facies_tract: null,
    section_id: "J",
    facies_color: null,
    lithology: "limestone",
    covered: false,
    flooding_surface_order: null,
    surface: null,
    note: null,
    definite_boundary: true,
    pattern: "limestone",
    schematic: false,
    fgdc_pattern: null,
    bottom: 567,
    top: 567.2,
    tree: ["carbonate", "limestone"],
    grainsize: "ms",
    surface_type: null,
    surface_order: null,
    surface_certainty: null,
    fill_pattern: "limestone",
  },
  {
    id: 475,
    facies: null,
    facies_tract: null,
    section_id: "J",
    facies_color: null,
    lithology: "shale",
    covered: false,
    flooding_surface_order: 5,
    surface: null,
    note: null,
    definite_boundary: true,
    pattern: "shale",
    schematic: false,
    fgdc_pattern: null,
    bottom: 567.2,
    top: 567.5,
    tree: ["clastic", "shale"],
    grainsize: "s",
    surface_type: "mfs",
    surface_order: 4,
    surface_certainty: null,
    fill_pattern: "shale",
  },
  {
    id: 476,
    facies: null,
    facies_tract: null,
    section_id: "J",
    facies_color: null,
    lithology: "limestone",
    covered: false,
    flooding_surface_order: null,
    surface: null,
    note: null,
    definite_boundary: true,
    pattern: "limestone",
    schematic: false,
    fgdc_pattern: null,
    bottom: 567.5,
    top: 567.7,
    tree: ["carbonate", "limestone"],
    grainsize: "ms",
    surface_type: null,
    surface_order: null,
    surface_certainty: null,
    fill_pattern: "limestone",
  },
  {
    id: 477,
    facies: null,
    facies_tract: null,
    section_id: "J",
    facies_color: null,
    lithology: "shale",
    covered: false,
    flooding_surface_order: 5,
    surface: null,
    note: null,
    definite_boundary: true,
    pattern: "shale",
    schematic: false,
    fgdc_pattern: null,
    bottom: 567.7,
    top: 568.1,
    tree: ["clastic", "shale"],
    grainsize: "s",
    surface_type: "mfs",
    surface_order: 4,
    surface_certainty: null,
    fill_pattern: "shale",
  },
  {
    id: 478,
    facies: null,
    facies_tract: null,
    section_id: "J",
    facies_color: null,
    lithology: "limestone",
    covered: false,
    flooding_surface_order: null,
    surface: null,
    note: null,
    definite_boundary: true,
    pattern: "limestone",
    schematic: false,
    fgdc_pattern: null,
    bottom: 568.1,
    top: 568.3,
    tree: ["carbonate", "limestone"],
    grainsize: "s",
    surface_type: null,
    surface_order: null,
    surface_certainty: null,
    fill_pattern: "limestone",
  },
  {
    id: 479,
    facies: null,
    facies_tract: null,
    section_id: "J",
    facies_color: null,
    lithology: "siltstone",
    covered: false,
    flooding_surface_order: 5,
    surface: null,
    note: null,
    definite_boundary: true,
    pattern: "siltstone",
    schematic: false,
    fgdc_pattern: null,
    bottom: 568.3,
    top: 568.8,
    tree: ["clastic", "siltstone"],
    grainsize: "s",
    surface_type: "mfs",
    surface_order: 4,
    surface_certainty: null,
    fill_pattern: "siltstone",
  },
  {
    id: 480,
    facies: null,
    facies_tract: null,
    section_id: "J",
    facies_color: null,
    lithology: "limestone",
    covered: false,
    flooding_surface_order: null,
    surface: null,
    note: null,
    definite_boundary: true,
    pattern: "limestone",
    schematic: false,
    fgdc_pattern: null,
    bottom: 568.8,
    top: 569,
    tree: ["carbonate", "limestone"],
    grainsize: "s",
    surface_type: null,
    surface_order: null,
    surface_certainty: null,
    fill_pattern: "limestone",
  },
  {
    id: 481,
    facies: null,
    facies_tract: null,
    section_id: "J",
    facies_color: null,
    lithology: "siltstone",
    covered: false,
    flooding_surface_order: 5,
    surface: null,
    note: null,
    definite_boundary: true,
    pattern: "siltstone",
    schematic: false,
    fgdc_pattern: null,
    bottom: 569,
    top: 569.5,
    tree: ["clastic", "siltstone"],
    grainsize: "s",
    surface_type: "mfs",
    surface_order: 3,
    surface_certainty: null,
    fill_pattern: "siltstone",
  },
  {
    id: 482,
    facies: "carbonate-mudstone",
    facies_tract: null,
    section_id: "J",
    facies_color: "#bbdefb",
    lithology: "lime mudstone",
    covered: false,
    flooding_surface_order: null,
    surface: null,
    note: null,
    definite_boundary: true,
    pattern: "lime_mudstone",
    schematic: false,
    fgdc_pattern: null,
    bottom: 569.5,
    top: 573,
    tree: ["carbonate", "limestone", "lime mudstone"],
    grainsize: "ms",
    surface_type: "sb",
    surface_order: null,
    surface_certainty: null,
    fill_pattern: "lime_mudstone",
  },
  {
    id: 483,
    facies: "hcs-grainstone",
    facies_tract: "shoal-shoreface",
    section_id: "J",
    facies_color: "#673ab7",
    lithology: "limestone",
    covered: false,
    flooding_surface_order: null,
    surface: null,
    note: null,
    definite_boundary: false,
    pattern: "limestone",
    schematic: false,
    fgdc_pattern: null,
    bottom: 573,
    top: 576,
    tree: ["carbonate", "limestone"],
    grainsize: "m",
    surface_type: "sb",
    surface_order: 1,
    surface_certainty: null,
    fill_pattern: "limestone",
  },
  {
    id: 569,
    facies: "mixed-grainstone",
    facies_tract: null,
    section_id: "J",
    facies_color: "#4a148c",
    lithology: null,
    covered: false,
    flooding_surface_order: null,
    surface: null,
    note: null,
    definite_boundary: true,
    pattern: null,
    schematic: false,
    fgdc_pattern: null,
    bottom: 576,
    top: 583.78,
    tree: null,
    grainsize: "m",
    surface_type: null,
    surface_order: null,
    surface_certainty: null,
    fill_pattern: null,
  },
  {
    id: 607,
    facies: null,
    facies_tract: null,
    section_id: "J",
    facies_color: null,
    lithology: null,
    covered: false,
    flooding_surface_order: 0,
    surface: null,
    note: null,
    definite_boundary: true,
    pattern: null,
    schematic: false,
    fgdc_pattern: null,
    bottom: 583.78,
    top: 585,
    tree: null,
    grainsize: "m",
    surface_type: null,
    surface_order: null,
    surface_certainty: 10,
    fill_pattern: null,
  },
  {
    id: 552,
    facies: "mixed-grainstone",
    facies_tract: null,
    section_id: "J",
    facies_color: "#4a148c",
    lithology: null,
    covered: false,
    flooding_surface_order: 2,
    surface: null,
    note: null,
    definite_boundary: true,
    pattern: null,
    schematic: false,
    fgdc_pattern: null,
    bottom: 585,
    top: 596,
    tree: null,
    grainsize: "m",
    surface_type: "sb",
    surface_order: 2,
    surface_certainty: null,
    fill_pattern: null,
  },
  {
    id: 484,
    facies: "mixed-grainstone",
    facies_tract: "marine-carbonate",
    section_id: "J",
    facies_color: "#4a148c",
    lithology: "limestone",
    covered: true,
    flooding_surface_order: 4,
    surface: null,
    note: null,
    definite_boundary: true,
    pattern: "limestone",
    schematic: false,
    fgdc_pattern: null,
    bottom: 596,
    top: 599.5,
    tree: ["carbonate", "limestone"],
    grainsize: "m",
    surface_type: "mfs",
    surface_order: 1,
    surface_certainty: null,
    fill_pattern: "limestone",
  },
  {
    id: 485,
    facies: "mixed-grainstone",
    facies_tract: null,
    section_id: "J",
    facies_color: "#4a148c",
    lithology: "limestone",
    covered: false,
    flooding_surface_order: null,
    surface: null,
    note: null,
    definite_boundary: false,
    pattern: "limestone",
    schematic: false,
    fgdc_pattern: null,
    bottom: 599.5,
    top: 605.5,
    tree: ["carbonate", "limestone"],
    grainsize: "m",
    surface_type: null,
    surface_order: null,
    surface_certainty: null,
    fill_pattern: "limestone",
  },
  {
    id: 486,
    facies: null,
    facies_tract: null,
    section_id: "J",
    facies_color: null,
    lithology: "limestone",
    covered: true,
    flooding_surface_order: null,
    surface: null,
    note: null,
    definite_boundary: true,
    pattern: "limestone",
    schematic: false,
    fgdc_pattern: null,
    bottom: 605.5,
    top: 610,
    tree: ["carbonate", "limestone"],
    grainsize: "m",
    surface_type: "mfs",
    surface_order: 3,
    surface_certainty: null,
    fill_pattern: "limestone",
  },
  {
    id: 487,
    facies: null,
    facies_tract: null,
    section_id: "J",
    facies_color: null,
    lithology: "limestone",
    covered: false,
    flooding_surface_order: 3,
    surface: null,
    note: null,
    definite_boundary: true,
    pattern: "limestone",
    schematic: false,
    fgdc_pattern: null,
    bottom: 610,
    top: 612,
    tree: ["carbonate", "limestone"],
    grainsize: "m",
    surface_type: "mfs",
    surface_order: 3,
    surface_certainty: null,
    fill_pattern: "limestone",
  },
  {
    id: 553,
    facies: null,
    facies_tract: "shoal-shoreface",
    section_id: "J",
    facies_color: null,
    lithology: null,
    covered: false,
    flooding_surface_order: 5,
    surface: null,
    note: null,
    definite_boundary: true,
    pattern: null,
    schematic: false,
    fgdc_pattern: null,
    bottom: 612,
    top: 628,
    tree: null,
    grainsize: "m",
    surface_type: null,
    surface_order: 1,
    surface_certainty: null,
    fill_pattern: null,
  },
  {
    id: 568,
    facies: "mixed-grainstone",
    facies_tract: null,
    section_id: "J",
    facies_color: "#4a148c",
    lithology: "limestone",
    covered: false,
    flooding_surface_order: null,
    surface: null,
    note: null,
    definite_boundary: true,
    pattern: "limestone",
    schematic: false,
    fgdc_pattern: null,
    bottom: 628,
    top: 630.35,
    tree: ["carbonate", "limestone"],
    grainsize: "m",
    surface_type: "mfs",
    surface_order: 4,
    surface_certainty: null,
    fill_pattern: "limestone",
  },
  {
    id: 700,
    facies: null,
    facies_tract: null,
    section_id: "J",
    facies_color: null,
    lithology: null,
    covered: false,
    flooding_surface_order: null,
    surface: null,
    note: null,
    definite_boundary: true,
    pattern: null,
    schematic: false,
    fgdc_pattern: null,
    bottom: 630.35,
    top: 649,
    tree: null,
    grainsize: null,
    surface_type: "mfs",
    surface_order: 4,
    surface_certainty: null,
    fill_pattern: null,
  },
  {
    id: 556,
    facies: "hcs-grainstone",
    facies_tract: "shoal-shoreface",
    section_id: "J",
    facies_color: "#673ab7",
    lithology: "limestone",
    covered: false,
    flooding_surface_order: -3,
    surface: null,
    note: null,
    definite_boundary: true,
    pattern: "limestone",
    schematic: false,
    fgdc_pattern: null,
    bottom: 649,
    top: 690,
    tree: ["carbonate", "limestone"],
    grainsize: "m",
    surface_type: "sb",
    surface_order: 2,
    surface_certainty: null,
    fill_pattern: "632",
  },
  {
    id: 632,
    facies: null,
    facies_tract: null,
    section_id: "J",
    facies_color: null,
    lithology: null,
    covered: false,
    flooding_surface_order: null,
    surface: null,
    note: null,
    definite_boundary: true,
    pattern: null,
    schematic: false,
    fgdc_pattern: null,
    bottom: 690,
    top: 669,
    tree: null,
    grainsize: null,
    surface_type: "mfs",
    surface_order: 0,
    surface_certainty: null,
    fill_pattern: null,
  },
];
